import { Injectable } from "@angular/core";
import { CanLoad, Route, Router, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../services/user.service";

@Injectable()
export class IsLogged implements CanLoad {
  constructor(private userService: UserService, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean>|Promise<boolean>|boolean {
    const isLogged = this.userService.isAuthenticated()
    if(isLogged) {
      const userCompany = this.userService.getUserCompany()
      this.router.navigateByUrl('/home/' + userCompany)
      return false
    }
    return !isLogged 
  }
}
<mat-card>
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>spa</mat-icon>
    </div>
    <mat-card-title>SAP - Variação</mat-card-title>
  </mat-card-header>

  <div class="row">
    <div
      fxFlex="100%"
      fxFlex.sm="100%"
      fxFlex.md="100%"
      fxFlex.lg="100%"
      class="row">
        <div
          fxFlex="60%"
          fxFlex.sm="60%"
          fxFlex.md="60%"
          fxFlex.lg="60%">
            
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z3">
            <!-- Name Column -->
            <ng-container matColumnDef="monthNameReference">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Mês Referência
              </th>
              <td mat-cell *matCellDef="let element">{{ element.monthNameReference }}</td>
            </ng-container>
            
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Valor Fatura
              </th>
              <td mat-cell *matCellDef="let element">{{ element.value  | currency : 'BRL' }}</td>
            </ng-container>
  
            <ng-container matColumnDef="variation">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Variação
              </th>
              <div>
              </div>
              <td 
                [ngClass]="
                {
                  'text-danger': element.variation < 0, 
                  'text-success': element.variation >=0
                }
                "
                mat-cell
                *matCellDef="let element">
                  <mat-icon class="mt-2">
                    {{ element.variation >=0 ? 'arrow_drop_up': 'arrow_drop_down'}}
                  </mat-icon>
                  <span>{{ element.variation | percentFormat : element.variation }}</span>
                </td>
            </ng-container>
        
            <ng-container matColumnDef="monthVariation">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Mês Variação
              </th>
              <td mat-cell *matCellDef="let element">{{ element.monthVariation }}</td>
            </ng-container>
        
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>

        <div
          fxFlex="38%"
          fxFlex.sm="38%"
          fxFlex.md="38%"
          fxFlex.lg="38%"
          fxLayout.gt-lg="column"
          fxLayoutAlign="start"
          class="selects ">
          <mat-form-field 
              class = "mr-1 select"
              appearance="outline"
              fxFlex="100%"
              fxFlex.sm="100%"
              fxFlex.md="100%"
              fxFlex.lg="100%"
              >
            <mat-label>Estado</mat-label>
            <mat-select (selectionChange)="OnSelectChangeState($event)" [(value)]="dealershipStateSelected">
              <mat-option valid value="">Todos</mat-option>
              <mat-option valid *ngFor="let item of dealershipsStates" value="{{item.state}}">{{item.state}}</mat-option>
            </mat-select>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
          </mat-form-field>
    
          <mat-form-field 
              class = "mr-1 select"
              appearance="outline"
              fxFlex="100%"
              fxFlex.sm="100%"
              fxFlex.md="100%"
              fxFlex.lg="100%"
              >
            <mat-label>Concessionária</mat-label>
            <mat-select (selectionChange)="OnSelectChangeDealership($event)" [(value)]="dealershipSelected">
              <mat-option valid value="">Todos</mat-option>
              <mat-option valid *ngFor="let item of dealerships" value="{{item.id}}">{{item.dealership}}</mat-option>
            </mat-select>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
          </mat-form-field>
    
          <mat-form-field 
              class = "mr-1 select"
              appearance="outline"
              fxFlex="100%"
              fxFlex.sm="100%"
              fxFlex.md="100%"
              fxFlex.lg="100%"
              >
            <mat-label>Filial</mat-label>
            <mat-select (selectionChange)="OnSelectConfig($event)" [(value)]="dealershipConfigSelected">
              <mat-option valid value="">Todos</mat-option>
              <mat-option valid *ngFor="let item of dealershipsFantasies" value="{{item.id}}">{{item.fantasyName}}</mat-option>
            </mat-select>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
          </mat-form-field>
    
          <mat-form-field 
              class = "mr-1 select"
              appearance="outline"
              fxFlex="100%"
              fxFlex.sm="100%"
              fxFlex.md="100%"
              fxFlex.lg="100%"
              >
            <mat-label>Refresh</mat-label>
            <mat-select (selectionChange)="OnSelectChangeRefresh($event)" [(value)]="refreshTime" [placeholder]="refreshTime">
              <mat-option valid *ngFor="let item of refreshes" value="{{item.time}}">{{item.text}}</mat-option>
            </mat-select>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
          </mat-form-field>

        </div>
    </div>
      
    </div>
    

</mat-card>
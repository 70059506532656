import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeComponent } from "./home.component";
import { HomeRoutes } from "./home.routing";
import { DashboardModule } from "../empresas/hapvida/dashboard/dashboard.module";

@NgModule({
  imports: [
    CommonModule, 
    HomeRoutes,
    DashboardModule
  ],
  declarations: [HomeComponent],
})
export class HomeModule {}

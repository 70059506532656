import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentFormat'
})
export class PercentFormatPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    const percent: string = value.toString().replace('.', ',').replace('-', '')
    return `%${percent}`
  }

}

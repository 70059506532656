import { Component, OnInit, ViewChild } from "@angular/core";

import { MatTableDataSource } from "@angular/material/table";
import { ClaroMovelInvoiceModel } from "src/app/models/claro-movel-invoice";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-claro-movel-faturas",
  templateUrl: "./claro-movel-faturas.component.html",
  styleUrls: ["./claro-movel-faturas.component.css"],
})
export class ClaroMovelFaturasComponent implements OnInit {
  private invoices: ClaroMovelInvoiceModel[] = [];
  constructor(private activatedRoute: ActivatedRoute) {}

  displayedColumns: string[] = [
    "invoiceValue",
    "invoiceDueDate",
    "invoiceStatus",
    "invoiceBarCode",
  ];
  dataSource = new MatTableDataSource<ClaroMovelInvoiceModel>([]);

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.invoices = this.activatedRoute.snapshot.data.invoices.data;
    this.dataSource = new MatTableDataSource<ClaroMovelInvoiceModel>(
      this.invoices
    );
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
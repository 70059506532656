import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DateFormatService } from './date-format.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private httpClient: HttpClient, private dateFormat: DateFormatService) { }

  getLogBotId(bot_id: string, fantasyName: string, dataInicial: string, dataFinal: string) {

    let params = new HttpParams();

    params = params.append('dataInicial', this.dateFormat.formatar_data(dataInicial))
    params = params.append('dataFinal', this.dateFormat.formatar_data(dataFinal))

    return this.httpClient.get(`${environment.apiUrl}/bot/log/list/${bot_id}/${fantasyName}`, {params})
  }

  download(bot_id: string, fantasyName: string, dataInicial: string, dataFinal: string) {
    let params = new HttpParams();

    params = params.append('dataInicial', this.dateFormat.formatar_data(dataInicial));
    params = params.append('dataFinal', this.dateFormat.formatar_data(dataFinal));

    return this.httpClient.get(`${environment.apiUrl}/bot/log/export/${bot_id}/${fantasyName}`, {params});
  }

}

<form [formGroup]="formConfig">

  <section
  fxLayout="row"
  fxLayout.xs="row wrap"
  fxLayoutGap.sm="4px"
  fxLayoutGap.md="4px"
  fxLayoutGap.lg="4px"
>

  <mat-form-field 
      class="mr-1"
      appearance="outline"
      fxFlex="100%"
      fxFlex.sm="100%"
      fxFlex.md="100%"
      fxFlex.lg="100%">
    <mat-label>Concessionária</mat-label>
    <mat-select (selectionChange)="OnSelectChange($event)" [(value)]="dealershipSelected">
      <mat-option disabled valid value="0">Selecione...</mat-option>
      <mat-option valid *ngFor="let item of configs" value="{{item.id}}">{{item.dealership}}</mat-option>
    </mat-select>
    <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
  </mat-form-field>

  <mat-form-field 
      class="mr-1"
      appearance="outline"
      fxFlex="100%"
      fxFlex.sm="100%"
      fxFlex.md="100%"
      fxFlex.lg="100%">
    <mat-label>Nome Fantásia</mat-label>
    <mat-select formControlName="fantasyName" (selectionChange)="OnSelectFantasy($event)" >
      <mat-option disabled valid value="0">Selecione...</mat-option>
      <mat-option valid *ngFor="let item of fantasies" value="{{item.id}}">{{item.registration}} - {{item.fantasyName}}</mat-option>
    </mat-select>
  </mat-form-field>
  
</section>
<section
    fxLayout="row"
    fxLayout.xs="row wrap"
    fxLayoutGap.sm="4px"
    fxLayoutGap.md="4px"
    fxLayoutGap.lg="4px"
  >
  
  <app-date-start-end (dates)="OnDateChange($event)"></app-date-start-end>
  
  </section>
  
  <div class="buttons">
    <button [disabled]=!fantasySelected (click)="OnRefresh()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>refresh</mat-icon>
    </button>
    <button [disabled]=!fantasySelected (click)="OnDownload()" class="ml-3" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>download_all</mat-icon>
    </button>
  </div>

  <div class="buttons">
    
  </div>
</form>

<div class="mat-elevation-z8">
  
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <!-- Name Column -->
  <ng-container matColumnDef="logMessage">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Log Mensagem
    </th>
    <td 
    [ngClass]="{
      'text-success': element.logStatus === 'Sucesso', 
      'text-warning': element.logStatus === 'ErroNegocio',
      'text-danger': element.logStatus === 'ErroSistema'}" 
    mat-cell *matCellDef="let element">{{ element.logMessage }}</td>
  </ng-container>

  <ng-container matColumnDef="logStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Log Status
    </th>
    <td 
    [ngClass]="{
      'text-success': element.logStatus === 'Sucesso', 
      'text-warning': element.logStatus === 'ErroNegocio',
      'text-danger': element.logStatus === 'ErroSistema'}" 
    mat-cell *matCellDef="let element">{{ element.logStatus }}</td>
  </ng-container>

  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Data Criação
    </th>
    <td 
    [ngClass]="{
      'text-success': element.logStatus === 'Sucesso', 
      'text-warning': element.logStatus === 'ErroNegocio',
      'text-danger': element.logStatus === 'ErroSistema'}" 
    mat-cell *matCellDef="let element">{{ element.created_at }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</div>
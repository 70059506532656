import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AlertifyService } from '../../services/alertify.service';

@Component({
  selector: 'app-date-start-end',
  templateUrl: './date-start-end.component.html',
  styleUrls: ['./date-start-end.component.css']
})
export class DateStartEndComponent implements OnInit {

  @Output() dates = new EventEmitter<any>();

  form: FormGroup

  constructor(private fb: FormBuilder, private alertifyService: AlertifyService) {  
    this.createForm();
  }

  private createForm() {
    this.form = this.fb.group({
      validDate: [true],
      dataInicial: [new Date()],
      dataFinal: [new Date()]
    });
  }

  ngOnInit() {
    this.dates.emit(this.form.value)
  }

  OnDateChangeStart(event) {
    this.form.get("validDate").setValue(true);

    if(this.form.get("dataInicial").value > this.form.get("dataFinal").value){
      this.form.get("validDate").setValue(false);
      this.alertifyService.error("Data inicial deve ser menor que data final");
      this.form.get("dataInicial").setValue(new Date(this.form.get("dataInicial").value));
      this.dates.emit(this.form.value)
      return
    }
      
    this.form.get("dataInicial").setValue(event.value);
    this.dates.emit(this.form.value)
  }

  OnDateChangeFinal(event) {
    
    if(this.form.get("dataFinal").value < this.form.get("dataInicial").value){
      this.form.get("validDate").setValue(false);
      this.alertifyService.error("Data final deve ser maior que data final");
      this.form.get("dataFinal").setValue(new Date(this.form.get("dataInicial").value));
      this.dates.emit(this.form.value)
      return
    }

    this.form.get("dataFinal").setValue(event.value);
    this.form.get("validDate").setValue(true);

    this.dates.emit(this.form.value)
  }
}

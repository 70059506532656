import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViacepService {

  private _baseUrl = "https://viacep.com.br/ws"

  constructor(private httpClient: HttpClient,) { }

  getByCep(cep: string) {
    return this.httpClient.get(`${this._baseUrl}/${cep}/json`)
  }

}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { UserModel } from "src/app/models/user.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(private httpClient: HttpClient) {}

  login(user: UserModel) {
    return this.httpClient.post(environment.apiUrl + "/users/login", user);
  }

  changePassword(value: any) {
    return this.httpClient.put(environment.apiUrl + "/users/changePassword", {...value})
  }

  resetUser({email}: UserModel) {
    return this.httpClient.put(environment.apiUrl + "/users/reset", {email});
  }
}

<form [formGroup]="form">
  <mat-card>
    <section>
      <p class="card-title-txt">
        Favor preencher os parâmetros solicitados
      </p>
    </section>

    <section
      fxLayout="row"
      fxLayout.xs="row wrap"
      fxLayoutGap.sm="4px"
      fxLayoutGap.md="4px"
      fxLayoutGap.lg="4px"
    >
      <mat-form-field
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="50%"
      >
        <mat-label>Nome</mat-label>
        <input
          matInput
          type="text"
          minlength="3"
          maxlength="40"
          required="true"
          placeholder="Nome"
          formControlName="nome"
        />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="50%"
      >
        <mat-label>CPF</mat-label>
        <input
          matInput
          type="number"
          minlength="8"
          maxlength="12"
          placeholder="000.000.000-00"
          formControlName="cpf"
        />
      </mat-form-field>
    </section>

    <section
      fxLayout="row"
      fxLayout.xs="row wrap"
      fxLayoutGap.sm="4px"
      fxLayoutGap.md="4px"
      fxLayoutGap.lg="4px"
    >
      <mat-form-field
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="50%"
      >
        <mat-label>Número do Contrato</mat-label>
        <input
          matInput
          type="number"
          minlength="3"
          maxlength="15"
          required="true"
          placeholder="00000"
          formControlName="numContrato"
        />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="50%"
      >
        <mat-label>UF</mat-label>
        <input
          matInput
          type="text"
          minlength="2"
          maxlength="2"
          placeholder="UF"
          formControlName="uf"
        />
      </mat-form-field>
    </section>

    <section
      fxLayout="row"
      fxLayout.xs="row wrap"
      fxLayoutGap.sm="4px"
      fxLayoutGap.md="4px"
      fxLayoutGap.lg="4px"
    >
      <mat-form-field
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="50%"
      >
        <mat-label>Mês</mat-label>
        <input
          matInput
          type="number"
          minlength="1"
          maxlength="2"
          required="true"
          placeholder="01"
          formControlName="mes"
        />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="50%"
      >
        <mat-label>Ano</mat-label>
        <input
          matInput
          type="text"
          minlength="2"
          maxlength="4"
          placeholder="2020"
          formControlName="ano"
        />
      </mat-form-field>
    </section>

    <section fxLayout="row" fxLayout="column">
      <mat-form-field fxFlex="100%" appearance="outline">
        <mat-label>Observação 1</mat-label>
        <textarea
          matInput
          placeholder="Escreva a observação se necessário"
          formControlName="obs1"
          style="height: 50px;"
        ></textarea>
      </mat-form-field>
    </section>
    <section fxLayout="row" fxLayout="column">
      <mat-form-field fxFlex="100%" appearance="outline">
        <mat-label>Observação 2</mat-label>
        <textarea
          matInput
          placeholder="Escreva a observação se necessário"
          formControlName="obs2"
          style="height: 50px;"
        ></textarea>
      </mat-form-field>
    </section>

    <mat-card-content>
      <section fxLayout="row" fxLayoutAlign="end" fxLayoutGap="4px">
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="!form.valid"
          (click)="OnSubmitForm()"
        >
          <mat-icon>done_all</mat-icon>
          Salvar
        </button>
      </section>
    </mat-card-content>
  </mat-card>
</form>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpClient: HttpClient, private userService: UserService, private dateFormat: DateFormatService) {}

  getSapMenMiroStatus(configId: string) {
    const company = this.userService.getUserCompany()

    let params = new HttpParams();
    params = params.append('configId', configId)
    
    return this.httpClient.get(environment.apiUrl + `/${company}/dashboard/menmiro`, {params})
  }

  getDashboardValues(configState, dealershipId, configId) {
    const userCompany = this.userService.getUserCompany();

    let params = new HttpParams()
    params = params.append('configState', configState)
    params = params.append('dealershipId', dealershipId)
    params = params.append('configId', configId)

    return this.httpClient.get(`${environment.apiUrl}/${userCompany}/dashboard/month/values`, {params})
  }

  download(dealership_id: string, dataInicial: string, dataFinal: string) {
    let params = new HttpParams();

    params = params.append('dataInicial', this.dateFormat.formatar_data(dataInicial))
    params = params.append('dataFinal', this.dateFormat.formatar_data(dataFinal))

    return this.httpClient.get(environment.apiUrl + "/hapvida/sap/menmiro/dealership/indexOrders/" + dealership_id + "/status/download", {params});

  }


  
  getVariation(configState, dealershipId, configId) {
    const userCompany = this.userService.getUserCompany();
    let params = new HttpParams()
    params = params.append('configState', configState)
    params = params.append('dealershipId', dealershipId)
    params = params.append('configId', configId)

    return this.httpClient.get(environment.apiUrl + `/${userCompany}/dashboard/month/variation`, {params});
  }

}

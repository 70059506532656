<form [formGroup]="formConfig">
  <mat-card>
    <section>
      <p class="card-title-txt">
        Favor preencher os parâmetros solicitados para configuração
      </p>
    </section>

    <section
      fxLayout="row"
      fxLayout.xs="row wrap"
      fxLayoutGap.sm="4px"
      fxLayoutGap.md="4px"
      fxLayoutGap.lg="4px"
    >
      <mat-form-field
        class="mr-1"
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="50%"
      >
        <mat-label>Usuário</mat-label>
        <input
          matInput
          type="email"
          minlength="3"
          maxlength="40"
          required="true"
          placeholder="usuario@example.com"
          formControlName="username"
        />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="50%"
        fxFlex.md="50%"
        fxFlex.lg="50%"
      >
        <mat-label>Senha <mat-icon>remove_red_eye</mat-icon></mat-label>
        <input
          matInput
          type="password"
          minlength="1"
          required="true"
          placeholder="*****"
          formControlName="password"
        />
      </mat-form-field>
    </section>

    <mat-card-content>
      <section fxLayout="row" fxLayoutAlign="end" fxLayoutGap="4px">
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="!formConfig.valid"
          (click)="OnSubmitFormConfig()"
        >
          <mat-icon>done_all</mat-icon>
          Salvar
        </button>
      </section>
    </mat-card-content>
  </mat-card>
</form>

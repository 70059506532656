import { Component, OnInit } from '@angular/core';
import { environmentSystem } from 'src/environments/environment-systm';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  version: string = environmentSystem.version
  constructor() { }

  ngOnInit() {
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SapService {

  constructor(private httpClient: HttpClient, private userService: UserService) {}

  getOrderInfo(invoiceId: string) {
    return this.httpClient.get(`${environment.apiUrl}/hapvida/sap/menmiro/config/indexOrder/${invoiceId}`)
  }

  getConfigSapOrders(config_id: string) {
    return this.httpClient.get(environment.apiUrl + "/hapvida/sap/menmiro/config/indexOrders/" + config_id);
  }

  getDealershipSapOrders(dealership_id: string, status: string) {
    let params = new HttpParams();

    // Begin assigning parameters
    params = params.append('status', status);
    
    return this.httpClient.get(environment.apiUrl + "/hapvida/sap/menmiro/dealership/indexOrders/" + dealership_id + "/status", {params});
  }


  updateSapActiveStatus(orderId: string, orderMessage: string) {
    return this.httpClient.put(environment.apiUrl + "/hapvida/sap/menmiro/update/order/status", {orderId, orderMessage});
  }

  
}

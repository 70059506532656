import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClaroMovelComponent } from "./claro-movel.component";
import { ClaroMovelRoutes } from "./claro-movel.routing";
import { ReactiveFormsModule } from "@angular/forms";
import { ComponentsModule } from "src/app/shared/components/components.module";
import { ClaroMovelService } from "./claro-movel.service";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpConfigInterceptor } from "src/app/shared/interceptors/httpconfig.interceptor";
import { ClaroMovelConfigComponent } from "./claro-movel-config/claro-movel-config.component";
import { ClaroMovelCadastroComponent } from "./claro-movel-cadastro/claro-movel-cadastro.component";
import { ClaroMovelFaturasComponent } from "./claro-movel-faturas/claro-movel-faturas.component";
import { CompanyGuard } from "src/app/shared/routes-guards/company-guard";

@NgModule({
  imports: [
    CommonModule,
    ClaroMovelRoutes,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
  ],
  declarations: [
    ClaroMovelComponent,
    ClaroMovelCadastroComponent,
    ClaroMovelConfigComponent,
    ClaroMovelFaturasComponent,
  ],
  providers: [
    ClaroMovelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    CompanyGuard
  ],
})
export class ClaroMovelModule {}

<mat-card>
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>spa</mat-icon>
    </div>
    <mat-card-title>SAP - Valores Mensais</mat-card-title>
  </mat-card-header>

  <div class="row">
    <div
      fxFlex="100%"
      fxFlex.sm="100%"
      fxFlex.md="100%"
      fxFlex.lg="100%"
      class="row">
        <div
          fxFlex="60%"
          fxFlex.sm="60%"
          fxFlex.md="60%"
          fxFlex.lg="60%">
            <ngx-charts-bar-vertical
            [scheme]="colorScheme"
            [results]="results"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [legend]="showLegend"
            [xAxisLabel]="xAxisLabel"
            [yAxisLabel]="yAxisLabel"
            [showDataLabel]=true>
            </ngx-charts-bar-vertical>

        </div>

        <div
          fxFlex="40%"
          fxFlex.sm="40%"
          fxFlex.md="40%"
          fxFlex.lg="40%"
          fxLayout.gt-lg="column">
          <mat-form-field 
              class = "mr-1"
              appearance="outline"
              fxFlex="100%"
              fxFlex.sm="100%"
              fxFlex.md="100%"
              fxFlex.lg="100%"
              >
            <mat-label>Estado</mat-label>
            <mat-select (selectionChange)="OnSelectChangeState($event)" [(value)]="dealershipStateSelected">
              <mat-option valid value="">Todos</mat-option>
              <mat-option valid *ngFor="let item of dealershipsStates" value="{{item.state}}">{{item.state}}</mat-option>
            </mat-select>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
          </mat-form-field>
    
          <mat-form-field 
              class = "mr-1"
              appearance="outline"
              fxFlex="100%"
              fxFlex.sm="100%"
              fxFlex.md="100%"
              fxFlex.lg="100%"
              >
            <mat-label>Concessionária</mat-label>
            <mat-select (selectionChange)="OnSelectChangeDealership($event)" [(value)]="dealershipSelected">
              <mat-option valid value="">Todos</mat-option>
              <mat-option valid *ngFor="let item of dealerships" value="{{item.id}}">{{item.dealership}}</mat-option>
            </mat-select>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
          </mat-form-field>
    
          <mat-form-field 
              class = "mr-1"
              appearance="outline"
              fxFlex="100%"
              fxFlex.sm="100%"
              fxFlex.md="100%"
              fxFlex.lg="100%"
              >
            <mat-label>Filial</mat-label>
            <mat-select (selectionChange)="OnSelectConfig($event)" [(value)]="dealershipConfigSelected">
              <mat-option valid value="">Todos</mat-option>
              <mat-option valid *ngFor="let item of dealershipsFantasies" value="{{item.id}}">{{item.fantasyName}}</mat-option>
            </mat-select>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
          </mat-form-field>
    
          <mat-form-field 
              class = "mr-1"
              appearance="outline"
              fxFlex="100%"
              fxFlex.sm="100%"
              fxFlex.md="100%"
              fxFlex.lg="100%"
              >
            <mat-label>Refresh</mat-label>
            <mat-select (selectionChange)="OnSelectChangeRefresh($event)" [(value)]="refreshTime" [placeholder]="refreshTime">
              <mat-option valid *ngFor="let item of refreshes" value="{{item.time}}">{{item.text}}</mat-option>
            </mat-select>
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
          </mat-form-field>

        </div>
      </div>
      
    </div>
    

</mat-card>
<mat-card>
  <mat-card-header>
    <div mat-card-avatar>
      <mat-icon>spa</mat-icon>
    </div>
    <mat-card-title>SAP - Lançamentos</mat-card-title>
  </mat-card-header>
  <div class="row">
  <app-date-start-end (dates)="OnDateChange($event)"></app-date-start-end>

    <button *ngIf="!downloading" (click)="OnDownload()" class="ml-3" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>download_all</mat-icon>
    </button>
    
    <div *ngIf="downloading" class="ml-3">
      <mat-progress-spinner [diameter]="50" mode="indeterminate"></mat-progress-spinner>
    </div>

  </div>

  <div class="row">
    <div
      fxFlex="100%"
      fxFlex.sm="100%"
      fxFlex.md="100%"
      fxFlex.lg="100%"
      class="row">
      <ngx-charts-advanced-pie-chart
        [view] = "view"
        [scheme]="colorScheme"
        [results]="results"
        [tooltipDisabled]=false
        (select)="OnSelectStatus($event)">
      </ngx-charts-advanced-pie-chart>


      <mat-form-field 
          class="mr-1"
          appearance="outline"
          fxFlex="20%"
          fxFlex.sm="20%"
          fxFlex.md="20%"
          fxFlex.lg="20%"
          >
        <mat-label>Concessionária</mat-label>
        <mat-select (selectionChange)="OnSelectChange($event)" [(value)]="dealershipSelected">
          <mat-option valid value="*">Todos</mat-option>
          <mat-option valid *ngFor="let item of dealerships" value="{{item.id}}">{{item.dealership}}</mat-option>
        </mat-select>
        <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
      </mat-form-field>

      <mat-form-field 
          class="mr-1"
          appearance="outline"
          fxFlex="10%"
          fxFlex.sm="10%"
          fxFlex.md="10%"
          fxFlex.lg="10%"
          >
        <mat-label>Refresh</mat-label>
        <mat-select (selectionChange)="OnSelectChangeRefresh()" [(value)]="refreshTime" [placeholder]="refreshTime">
          <mat-option valid *ngFor="let item of refreshes" value="{{item.time}}">{{item.text}}</mat-option>
        </mat-select>
        <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
      </mat-form-field>
      

    </div>
    
    <div
      fxFlex="80%"
      fxFlex.sm="80%"
      fxFlex.md="80%"
      fxFlex.lg="80%"
      >
      
      <div *ngIf="tableStatus.length" class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
          <!-- Name Column -->
          <ng-container matColumnDef="dealership">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Concessionária
            </th>
            <td mat-cell *matCellDef="let element">{{ element.dealership }}</td>
          </ng-container>
          
          <ng-container matColumnDef="registration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Matricula
            </th>
            <td mat-cell *matCellDef="let element">{{ element.registration }}</td>
          </ng-container>

          <ng-container matColumnDef="invoiceDueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Ordem Data Vencimento
            </th>
            <td mat-cell *matCellDef="let element">{{ element.invoiceDueDate }}</td>
          </ng-container>
      
          <ng-container matColumnDef="invoiceReferenceMonth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Ordem Data Referência
            </th>
            <td mat-cell *matCellDef="let element">{{ element.invoiceReferenceMonth }}</td>
          </ng-container>
      
          <!-- Position Column -->
          <ng-container matColumnDef="invoiceValue">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ordem Valor</th>
            <td mat-cell *matCellDef="let element">{{ element.invoiceValue | currency : 'BRL' }}</td>
          </ng-container>
      
          <div *ngIf="currentStatus.toLowerCase() !== 'resolvido'">
            <ng-container matColumnDef="sapStatusMensagem">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Ordem Mensagem
              </th>
              <td mat-cell *matCellDef="let element">{{ element.sapStatusMensagem }}</td>
            </ng-container>
          </div>

          <div *ngIf="currentStatus.toLowerCase() === 'resolvido'">
            <ng-container matColumnDef="sapStatusMensagem">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Ordem Mensagem
              </th>
              <td mat-cell *matCellDef="let element">
                <a class="ml-2 btn btn-info buttons rounded mt-2" (click)="OnGetResolveOrderMessage(element.userEmail, element.message)">Ver Mensagem</a>
              </td>
            </ng-container>
          </div>

          <!-- Symbol Column -->
          <ng-container matColumnDef="updated_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Ordem Data Ataulização
            </th>
            <td mat-cell *matCellDef="let element">{{ element.updated_at | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
          </ng-container>
      
          <ng-container matColumnDef="acoes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Ações
            </th>
            <td mat-cell *matCellDef="let element">
              <a target="_blank" (click)="OnDetailClick(element.id)" class="btn btn-viseu rounded mt-2">Detalhes</a>
              <a disabled *ngIf="element.sapStatus == 'ERRO_NEGOCIO'" class="ml-2 btn btn-warning buttons rounded mt-2" (click)="OnResolveSubmit(element.id)">Resolver</a>
            </td>
      
          </ng-container>
          
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    
    </div>
  </div>

</mat-card>
import { Routes, RouterModule } from "@angular/router";
import { ClaroMovelComponent } from "./claro-movel.component";
import { ClaroMovelConfigResolve } from "./claro-movel-config/claro-movel-config.resolve";
import { ClaroMovelInvoiceResolve } from "./claro-movel-faturas/claro-movel-faturas.resolve";

const routes: Routes = [
  {
    path: "",
    component: ClaroMovelComponent,
    resolve: {
      config: ClaroMovelConfigResolve,
      invoices: ClaroMovelInvoiceResolve,
    },
  },
];

export const ClaroMovelRoutes = RouterModule.forChild(routes);

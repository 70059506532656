import { SidenavItem } from "src/app/models/sidenav-item.model";

// Link icons: https://materializecss.com/icons.html
export class SidenavItemFactory {
  public static buildSidenav(empresa:string ): SidenavItem[] {
    const sidenavItems: SidenavItem[] = [];

    const empresasCompatilhadas = 'hapvida'
    if(empresa == 'viseu') {
      sidenavItems.push(
        new SidenavItem("add_circle", "Claro Móvel", `${empresa}/claro-movel`)
      );
      sidenavItems.push(new SidenavItem("add_circle", "Enel", `${empresa}/enel`));
      sidenavItems.push(new SidenavItem("add_circle", "IPTU", `${empresa}/iptu`));
      sidenavItems.push(
        new SidenavItem("library_books", "Relatórios", `${empresa}/relatorio`)
      );
    }

    if(empresasCompatilhadas.includes(empresa)) {
      sidenavItems.push(new SidenavItem("add_circle", "Concessionárias", `${empresa}/concessionarias`));
    }
    return sidenavItems;
  }
}

// add_circle_outline

<main class="main mt-4 container" fxFlex="100%">
  <section fxLayout="column">
    <app-button-modules (mode)="ChangeMode($event)"></app-button-modules>

    <section fxFlex.xs="80%">
      <!-- FORMULÁRIO CADASTRO -->
      <app-enel-cadastro *ngIf="mode === 'form'"></app-enel-cadastro>

      <!-- FORMULARIO CONFIGURACAO -->
      <app-enel-config *ngIf="mode === 'config'"></app-enel-config>

      <app-enel-faturas *ngIf="mode === 'faturas'"></app-enel-faturas>
    </section>
  </section>
</main>

<section fxFlex.xs="20%">
  <div class="buttons row">
    <button (click)="ChangeMode('config')" class="btn btn-primary mr-1 mb-1">
      Configuração
    </button>
    <button (click)="ChangeMode('faturas')" class="btn btn-primary mr-1 mb-1">
      Faturas
    </button>
    <button (click)="ChangeMode('logs')" class="btn btn-primary mr-1 mb-1">
      Logs
    </button>
    <button (click)="ChangeMode('export')" class="btn btn-primary mr-1 mb-1">
      Exportar
    </button>
  </div>
</section>

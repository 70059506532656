import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AngularMaterialModule } from "./angular-material/angular-material.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { MenuComponent } from "./menu/menu.component";
import { ButtonModulesComponent } from "./button-modules/button-modules.component";
import { ButtonComponent } from "./button/button.component";
import { DialogOrderResolveComponent } from "./dialog-order-resolve/dialog-order-resolve.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogOrderResolveMessageComponent } from "./dialog-order-resolve-message/dialog-order-resolve-message.component";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from "@angular/material/core";
import { DateStartEndComponent } from "./date-start-end/date-start-end.component";
import { AngularSelectFilterModule } from "./select-filter/angular-material.module";

@NgModule({
  imports: [
    CommonModule, 
    ReactiveFormsModule,
    AngularMaterialModule, 
    FlexLayoutModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AngularSelectFilterModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    ButtonModulesComponent,
    ButtonComponent,
    DialogOrderResolveComponent,
    DialogOrderResolveMessageComponent,
    DateStartEndComponent
  ],
  exports: [
    AngularMaterialModule,
    FlexLayoutModule,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    ButtonModulesComponent,
    ButtonComponent,
    DialogOrderResolveComponent,
    DialogOrderResolveMessageComponent,
    DateStartEndComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    AngularSelectFilterModule
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule,
  ]
})
export class ComponentsModule {}

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ClaroMovelService } from "./claro-movel.service";
import { ClaroMovelConfigModel } from "src/app/models/claro-movel-config";
import { ResponseModel } from "src/app/models/response";
import { AlertifyService } from "src/app/shared/services/alertify.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-claro-movel",
  templateUrl: "./claro-movel.component.html",
  styleUrls: ["./claro-movel.component.css"],
})
export class ClaroMovelComponent implements OnInit {
  mode = "form";

  constructor() {}

  ngOnInit() {}

  ChangeMode(mode: string) {
    this.mode = mode;
  }
}

import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthenticationModule } from "./pages/authentication/authentication.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeModule } from "./pages/home/home.module";
import { ComponentsModule } from "./shared/components/components.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

/**
 * IMPORTAR MOEDA FORMATO BRASIL
 */
import { LOCALE_ID } from '@angular/core';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';

registerLocaleData(ptBr);

import { HttpConfigInterceptor } from "./shared/interceptors/httpconfig.interceptor";
import { GenericRoutingModule } from "./routes/generic.routes";
import { TemplateRoutingModule } from "./routes/template.routes";
import { ErrorInterceptorProvider } from "./shared/interceptors/error.interceptor";
import { HapvidaRoutingModule } from "./routes/hapvida.routes";
import { ClaroMovelModule } from "./pages/empresas/shared/claro-movel/claro-movel.module";
import { EnelModule } from "./pages/empresas/shared/enel/enel.module";
import { ConcessionariaModule } from "./pages/empresas/shared/concessionaria/concessionaria.module";
import { HapvidaFaturasModule } from "./pages/empresas/hapvida/hapvida-faturas/hapvida-faturas.module";
import { DashboardModule } from "./pages/empresas/hapvida/dashboard/dashboard.module";
import { IsLogged } from "./shared/routes-guards/is-logged";
import { FirstLoginGuard } from "./shared/routes-guards/first-login-guard";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    GenericRoutingModule,
    TemplateRoutingModule,
    HapvidaRoutingModule,
    // AppRoutingModule,
    AuthenticationModule,
    HttpClientModule,
    HomeModule,
    ClaroMovelModule,
    EnelModule,
    HapvidaFaturasModule,
    ConcessionariaModule,
    DashboardModule,
    ComponentsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    ErrorInterceptorProvider,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt' },
    IsLogged,
    FirstLoginGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

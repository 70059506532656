import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { UserService } from "../services/user.service";

@Injectable()
export class FirstLoginGuard implements CanActivate  {
  constructor(
    private userService: UserService,
    private router: Router,
    ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.userService.isAuthenticated()) {
      this.userService.logOut()
      return false;
    }
    const userFirstLogin = this.userService.getUserFirstLogin();
    const userToken = this.userService.getToken();

    if(userFirstLogin) {
      this.router.navigate(['reset/token/', userToken])
      return false
    }

    return true;
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcessionariaInvoiceModel } from 'src/app/models/concessionaria-invoice';
import { ConcessionariaParamModel } from 'src/app/models/concessionaria-params';
import { DashboardStatus } from 'src/app/models/dashboard-status';
import { ResponseModel } from 'src/app/models/response';
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import { SapService } from 'src/app/shared/services/sap-service.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DashboardService } from '../dashboard.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogOrderResolveComponent } from 'src/app/shared/components/dialog-order-resolve/dialog-order-resolve.component';
import { DialogOrderResolveMessageComponent } from 'src/app/shared/components/dialog-order-resolve-message/dialog-order-resolve-message.component';
import { DownloadStaticFilesService } from 'src/app/shared/services/download-static-files.service';
import { ExcelService } from 'src/app/shared/services/excel.service';

@Component({
  selector: 'app-dashboard-sap-men-miro',
  templateUrl: './dashboard-sap-men-miro.component.html',
  styleUrls: ['./dashboard-sap-men-miro.component.css']
})
export class DashboardSapMenMiroComponent implements OnInit {

  view = [800, 200]
  results: DashboardStatus[] = [];

  showLegend = true;
  showLabels = true;
  colorScheme: any;
  themeSubscription: any;

  form: FormGroup;
  dealershipSelected: any;
  dealership_id: string = '*';
  dealerships: ConcessionariaParamModel[];
  currentStatus: string;

  refreshTime: number = 30;
  refreshes = [
    {
      text: "30 Segundos",
      time: 30
    },
    {
      text: "60 Segundos",
      time: 60
    },
    {
      text: "120 Segundos",
      time: 120
    },
    {
      text: "180 Segundos",
      time: 180
    },
  ]
  timer: any

  resolveMessage: string = '';

  tableStatus: ConcessionariaInvoiceModel[] = []

  displayedColumns: string[] = [
    "dealership",
    "registration",
    "invoiceDueDate",
    "invoiceReferenceMonth",
    "invoiceValue",
    "sapStatusMensagem",
    "updated_at",
    "acoes"
  ];

  dataSource = new MatTableDataSource<ConcessionariaInvoiceModel>([]);

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  downloading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private alertifyService: AlertifyService,
    private sapService: SapService,
    private userService: UserService,
    public dialog: MatDialog,
    private excelService: ExcelService
  ) {
    const sapStatus = this.activatedRoute.snapshot.data.sapMenMiroStatus.data as DashboardStatus[];
    const dealerships = this.activatedRoute.snapshot.data.dealerships;
    if (dealerships) {
      this.dealerships = dealerships.data;
    }

    this.results = sapStatus;

    this.colorScheme = {
      domain: [ '#5AA454', '#ffff00', '#FF8C00', '#ff0000', '#FFFFF']
    };

    this.createForm();

  }

  createForm() {
    this.form = this.formBuilder.group({
      dataInicial: [""],
      dataFinal: [""]
    })
  }

  ngOnInit() {
  }

  OnSelectChangeRefresh() {
    clearInterval(this.timer)
    this.timer = setInterval(() => {
      this.dashboardService.getSapMenMiroStatus(this.dealership_id)
      .subscribe(
        (res: ResponseModel) => {
          this.results = res.data
        },
        (err: ResponseModel) => {

          this.alertifyService.error(err.message)
        }
      )
    }, this.refreshTime * 1000)
  }

  OnSelectChange(event: MatSelectChange) {
    this.tableStatus = []
    this.dealership_id = event.value
    this.dashboardService.getSapMenMiroStatus(this.dealership_id)
      .subscribe(
        (res: ResponseModel) => {
          this.results = res.data
        },
        (err: ResponseModel) => {

          this.alertifyService.error(err.message)
        }
      )
  }

  OnSelectStatus(event) {
    const status = event.name
    this.currentStatus = status
    this.sapService.getDealershipSapOrders(this.dealership_id, status)
      .subscribe(
        (res: ResponseModel) => {
          this.tableStatus = res.data
          this.dataSource = new MatTableDataSource<any>(this.tableStatus);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        (err: ResponseModel) => {

          this.alertifyService.error(err.message)
        }
      )
  }

  OnResolveSubmit(id) {
    const dialogRef = this.dialog.open(DialogOrderResolveComponent, {
      width: '600px',
      height: '400px'
    });

    dialogRef.afterClosed().subscribe(message => {

      if (message) {
        this.alertifyService.confirm('Você deseja resolver essa ordem ?', () => {
          this.sapService.updateSapActiveStatus(id, message)
          .subscribe(
            (res: ResponseModel) => {
              this.tableStatus = this.tableStatus.filter(status => status.id !== id)

              this.dataSource = new MatTableDataSource<any>(this.tableStatus);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;

              this.alertifyService.success(res.message)

              this.dashboardService.getSapMenMiroStatus(this.dealership_id)
                .subscribe(
                  (res: ResponseModel) => {
                    this.results = res.data
                  },
                  (err: ResponseModel) => {

                    this.alertifyService.error(err.message)
                  }
                )
            },
            (err: ResponseModel) =>{

            }
          )
        })
      }
    });


  }

  OnPageChange(page) {

  }

  OnDetailClick(id) {
    const company = this.userService.getUserCompany();
    let url = `${window.location.href.split("/#")[0]}/#/${company}/concessionarias/fatura/${id}`
    window.open(url)
  }

  OnGetResolveOrderMessage(userEmail: string, message: string): void {
    this.dialog.open(DialogOrderResolveMessageComponent, {
      width: '600px',
      height: '400px',
      data: {userEmail, message}
    });
  }

  OnDownload() {
    this.downloading = true;

    const dataInicial = this.form.get("dataInicial").value;
    const dataFinal = this.form.get("dataFinal").value;

    this.dashboardService.download(this.dealership_id, dataInicial, dataFinal)
      .subscribe(
        (res:ResponseModel) => {

          const data = res.data.map(invoice => {

            return {
              ...invoice,
              Data_Vencimento: `${invoice.Data_Vencimento.substring(0,4)}.${invoice.Data_Vencimento.substring(4,6)}.${invoice.Data_Vencimento.substring(6,8)}`,
              MesReferencia: `${invoice.MesReferencia.substring(0,4)}.${invoice.MesReferencia.substring(4,6)}`
            }
          })

          const headers = [
            [
              'ID', 'COD. DO FORNECEDOR (SAP)', 'CONCESSIONARIA', 'NOME DA UNIDADE', 'MATRICULA',
              'DATA VENCIMENTO', 'MÊS REFERENCIA', 'VALOR', 'Nº DO PEDIDO SAP ', 'Nº DA ZMIRO (SAP)', 'STATUS DO PROCESSO',
              'CENTRO DE CUSTO', 'COD. DO CENTRO FINANCEIRO', 'ITEM FINANCEIRO', 'DEBITO AUTOMATICO',
              'COD. DO DÉBITO AUTOMATICO', 'STATUS DA UNIDADE', 'DATA DA LEITURA', 'DATA CRIAÇÃO PEDIDO', 'DATA ATUALIZAÇÃO PEDIDO',
              'MENSAGEM DE ERRO SAP - ME21', 'MENSAGEM DE ERRO SAP - MIRO'
            ]
          ]
          this.excelService.exportAsExcelFile(data, "RelatorioSap", headers)
          this.downloading = false;
        },
        (err: ResponseModel) => {
          this.downloading = false;
          this.alertifyService.error(err.message)
        }
      )
  }

  OnDateChange(event) {
    this.form.get("dataInicial").setValue(event.dataInicial)
    this.form.get("dataFinal").setValue(event.dataFinal)
  }
}

<main class="main mt-4 container" fxFlex="100%">
    <section fxLayout="column">
      <section fxFlex.xs="80%">
        <div>
          <app-dashboard-sap-men-miro></app-dashboard-sap-men-miro>
        </div>
        
    </section>
  </section>
  </main>
  
    
  
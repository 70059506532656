<main class="main" fxLayout="column" fxLayoutAlign="start center">
  <section fxLayoutAlign="center center">
    <mat-card
      fxFlex.xs="96vw"
      fxFlex.sm="80vw"
      fxFlex.md="64vw"
      fxFlex.lg="48vw"
      style="margin-top: 30px;"
    >
      <mat-card-header>
        <div mat-card-avatar>
          <mat-icon>spa</mat-icon>
        </div>
        <mat-card-title>Gestor de Concessionárias - Reset Senha</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <form [formGroup]="form">

          <!-- INFORMAR SENHA  -->
          <section>
            <mat-form-field fxFlex="100%">
              <input
                matInput
                #input_password
                [type]="inputPassword"
                required="true"
                placeholder="Informe sua senha"
                formControlName="password"
                matInput [type]="hide ? 'password' : 'text'"
                />
  
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>

              <mat-error>A senha informada é inválida.</mat-error>
            </mat-form-field>
          </section>

          <!-- CONFIRMAR SENHA  -->
          <section>
            <mat-form-field fxFlex="100%">
              <input
                matInput
                #input_password
                [type]="inputPassword"
                required="true"
                placeholder="Confirme sua senha"
                formControlName="confirmPassword"
                [errorStateMatcher]="matcher"
                matInput [type]="hideConfirm ? 'password' : 'text'"
              />

              <button mat-icon-button matSuffix (click)="hideConfirm = !hideConfirm" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>

              <mat-error *ngIf="form.hasError('notSame') && form.get('confirmPassword').value !== ''" >
                Senhas devem ser iguais!
              </mat-error>  
            </mat-form-field>
          </section>

          <section fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="15px">
            <button
              type="submit"
              mat-raised-button
              color="primary"
              [disabled]="form?.invalid"
              cdkFocusInitial
              (click)="submitForm()"
            >
              <mat-icon>lock</mat-icon>
              Acessar sistema
            </button>
          </section>
        </form>
      </mat-card-content>
    </mat-card>
  </section>
</main>

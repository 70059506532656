import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ClaroMovelService } from "../claro-movel.service";
import { ClaroMovelInvoiceModel } from "src/app/models/claro-movel-invoice";

@Injectable({ providedIn: "root" })
export class ClaroMovelInvoiceResolve
  implements Resolve<ClaroMovelInvoiceModel[]> {
  constructor(private claroMovelService: ClaroMovelService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<ClaroMovelInvoiceModel[]>
    | Promise<ClaroMovelInvoiceModel[]>
    | ClaroMovelInvoiceModel[] {
    return this.claroMovelService.getInvoices().pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }
}

<main class="main mt-4" fxFlex="100%">
  <section fxLayout="column">
    <app-button-modules (mode)="ChangeMode($event)"></app-button-modules>

    <section fxFlex.xs="80%">
      <!-- FORMULÁRIO CADASTRO -->
      <app-claro-movel-cadastro
        *ngIf="mode === 'form'"
      ></app-claro-movel-cadastro>

      <!-- FORMULARIO CONFIGURACAO -->
      <app-claro-movel-config
        *ngIf="mode === 'config'"
      ></app-claro-movel-config>

      <app-claro-movel-faturas
        *ngIf="mode === 'faturas'"
      ></app-claro-movel-faturas>
    </section>
  </section>
</main>

import { Routes, RouterModule } from '@angular/router';
import { ConcessionariaConfigResolve } from 'src/app/shared/resolves/concessionaria-config.resolve';
import { IbgeConsultaEstadosResolve } from 'src/app/shared/resolves/Ibge-consulta-estados';
import { ConcessionariaComponent } from './concessionaria.component';

const routes: Routes = [
  {
    path: "",
    component: ConcessionariaComponent,
    resolve: {
      config: ConcessionariaConfigResolve,
      states: IbgeConsultaEstadosResolve
    }
  },
];

export const ConcessionariaRoutes = RouterModule.forChild(routes);

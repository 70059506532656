import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ConcessionariaConfigModel } from "src/app/models/concessionaria-config";
import { DashboardService } from "src/app/pages/empresas/hapvida/dashboard/dashboard.service";
import { ConcessionariaService } from "src/app/shared/services/concessionaria.service";

@Injectable({ providedIn: "root" })
export class DashboardVariationResolve implements Resolve<ConcessionariaConfigModel[]> {

  constructor(private dashboardService: DashboardService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<ConcessionariaConfigModel[]>
    | Promise<ConcessionariaConfigModel[]>
    | ConcessionariaConfigModel[] {
    return this.dashboardService.getVariation('', '', '').pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }
}

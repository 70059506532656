import { Component, OnInit } from "@angular/core";
import { UserService } from "./shared/services/user.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  constructor(private userService: UserService) {}

  user$: Observable<any>;

  ngOnInit() {
    this.user$ = this.userService.getUser();
  }
}

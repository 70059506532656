import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ConcessionariaFantasyModel } from 'src/app/models/concessionaria-fantasy';
import { ConcessionariaInvoiceModel } from 'src/app/models/concessionaria-invoice';
import { ConcessionariaParamModel } from 'src/app/models/concessionaria-params';
import { LogModel } from 'src/app/models/log';
import { ResponseModel } from 'src/app/models/response';
import { SapMe21nMiro } from 'src/app/models/sap-me21n-miro';
import { DialogOrderResolveComponent } from 'src/app/shared/components/dialog-order-resolve/dialog-order-resolve.component';
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import { ConcessionariaService } from 'src/app/shared/services/concessionaria.service';
import { LogService } from 'src/app/shared/services/log.service';
import { SapService } from 'src/app/shared/services/sap-service.service';

@Component({
  selector: 'app-concessionaria-faturas',
  templateUrl: './concessionaria-faturas.component.html',
  styleUrls: ['./concessionaria-faturas.component.css']
})
export class ConcessionariaFaturasComponent implements OnInit {

  form: FormGroup
  configs: ConcessionariaParamModel[] = [];

  configSelected: ConcessionariaParamModel;

  fantasies: ConcessionariaFantasyModel[] = []
  fantasySelected: ConcessionariaFantasyModel

  dealershipSelected: any = "0";

  dataSource = new MatTableDataSource<ConcessionariaInvoiceModel>([]);

  displayedColumns: string[] = [
    "invoiceDueDate",
    "invoiceReferenceMonth",
    "invoiceValue",
    "sapStatus",
    "created_at",
    "acoes"
  ];

  invoices: SapMe21nMiro[] = []

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private fb: FormBuilder,
    private concessionariaService: ConcessionariaService,
    private sapService: SapService,
    private activatedRoute: ActivatedRoute,
    private alertifyService: AlertifyService,
    public dialog: MatDialog
    ) { 
      this.createForm();
    }

  private createForm() {
    this.form = this.fb.group({
      fantasyName: [""],
    });
  }

  ngOnInit() {
    const existsConfig = this.activatedRoute.snapshot.data.config;
    if (existsConfig) {
      this.configs = existsConfig.data;
    }
    this.dealershipSelected = "0"
    this.form.get('fantasyName').disable()
  }

  OnSelectChange(event) {
    this.dataSource = new MatTableDataSource<any>(this.invoices);
    this.configSelected = this.configs.filter(config => config.id == event.value)[0]
    this.concessionariaService.getParamFantasy(event.value)
      .subscribe(
        (res:ResponseModel) => {

          this.fantasies = res.data
          this.form.get('fantasyName').enable()

        },
        (err: ResponseModel) => {
          this.alertifyService.error(err.message)
        }
      )
  }

  OnSelectFantasy(event) {
    this.fantasySelected = this.fantasies.filter(fantasy => fantasy.id == event.value)[0]

    this.sapService.getConfigSapOrders(this.fantasySelected.id)
    .subscribe(
      (res: ResponseModel) =>{
        this.invoices = res.data
        
        this.dataSource = new MatTableDataSource<any>(this.invoices);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (error: ResponseModel) => {
        
      }
    )

  }

  OnResolveSubmit(id) {
      const dialogRef = this.dialog.open(DialogOrderResolveComponent, {
        width: '600px',
        height: '400px'
      });
  
      dialogRef.afterClosed().subscribe(message => {
        
        if (message) {
          this.alertifyService.confirm('Você deseja resolver essa ordem ?', () => {
            this.sapService.updateSapActiveStatus(id, message)
            .subscribe(
              (res: ResponseModel) => {
                this.invoices = this.invoices.filter(invoice => invoice.id !== id)
                this.dataSource = new MatTableDataSource<any>(this.invoices);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
      
                this.alertifyService.success(res.message)
              },
              (err: ResponseModel) =>{
                
              }
            )
          })
        }
      });
  
     
  }

  

  // ngOnInit() {
  //   
  //   this.invoices = this.activatedRoute.snapshot.data.invoices.data;
  //   
  //   this.dataSource = new MatTableDataSource<EnelInvoiceModel>(this.invoices);
  //   // this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  compare( a, b ) {
    if ( a.invoiceReferenceMonth < b.invoiceReferenceMonth ){
      return 1;
    }
    if ( a.invoiceReferenceMonth > b.invoiceReferenceMonth ){
      return -1;
    }
    return 0;
  }

}

<section>
  <h1 mat-dialog-title>Grupo Viseu - Resolver Ordem</h1>
  <div mat-dialog-content>
    <p>Descreva abaixo o motivo de estar resolvendo manualmente essa ordem.</p>
    <p>Lembrando que após resolver a ordem, sua mensagem ficará registrada como resolução.</p>
    <mat-form-field [style.width.px]=500 appearance="fill">
      <mat-label>Descrição...</mat-label>
      <textarea matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      [(ngModel)]="message"></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button [mat-dialog-close]="message" cdkFocusInitial>Ok</button>
  </div>
</section>
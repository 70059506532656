import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { AuthenticationRoutes } from "./authentication.routing";
import { ComponentsModule } from "src/app/shared/components/components.module";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthenticationRoutes,
    ComponentsModule,
    HttpClientModule,
  ],
  declarations: [LoginComponent, ResetPasswordComponent],
})
export class AuthenticationModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutes } from './dashboard.routing';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { DashboardSapMenMiroComponent } from './dashboard-sap-men-miro/dashboard-sap-men-miro.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DashboardVariacaoPrecoComponent } from './dashboard-variacao-preco/dashboard-variacao-preco.component';
import { DashboardValoresBarrasComponent } from './dashboard-valores-barras/dashboard-valores-barras.component';
import { PercentFormatPipe } from 'src/app/shared/pipe/percent-format.pipe';


@NgModule({
  imports: [
    CommonModule,
    NgxChartsModule,
    DashboardRoutes,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    MatPaginatorModule,
    MatIconModule,
    
  ],
  declarations: [
    DashboardComponent, 
    DashboardSapMenMiroComponent,
    DashboardVariacaoPrecoComponent,
    DashboardValoresBarrasComponent,
    PercentFormatPipe
  ],
  exports: [
    DashboardComponent, 
    DashboardSapMenMiroComponent,
    DashboardVariacaoPrecoComponent,
    DashboardValoresBarrasComponent
  ],
  
})
export class DashboardModule { }

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { UserService } from "../services/user.service";

@Injectable()
export class CompanyGuard implements CanActivate  {
  constructor(
    private userService: UserService,
    private router: Router,
    ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.userService.isAuthenticated()) {
      this.userService.logOut()
      return false;
    }

    const urlCompany = route.paramMap.get('company');

    const userCompany = this.userService.getUserCompany();
    
    if(urlCompany !== userCompany) {
      this.router.navigate(['home', userCompany])
      return false
    }

    return true;
  }
}
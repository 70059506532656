import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ConcessionariaConfigModel } from "src/app/models/concessionaria-config";
import { ResponseModel } from "src/app/models/response";
import { AlertifyService } from "src/app/shared/services/alertify.service";
import { SapService } from "src/app/shared/services/sap-service.service";
import { FaturaService } from "./fatura.service";

@Injectable({ providedIn: "root" })
export class HapVidaFaturaInfoResolve implements Resolve<ConcessionariaConfigModel[]> {

  constructor(private sapService: SapService, private router: Router, private alertifyService: AlertifyService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<ConcessionariaConfigModel[]>
    | Promise<ConcessionariaConfigModel[]>
    | ConcessionariaConfigModel[] {

    const invoiceId: string = route.params.id
    return this.sapService.getOrderInfo(invoiceId).pipe(
      catchError((error: ResponseModel) => {
        this.alertifyService.error(error.message)
        this.router.navigateByUrl('hapvida/concessionarias')
        return of(null);
      })
    );
  }
}

import { Component, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button-modules",
  templateUrl: "./button-modules.component.html",
  styleUrls: ["./button-modules.component.css"],
})
export class ButtonModulesComponent implements OnInit {
  @Output() mode = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  ChangeMode(mode: string) {
    this.mode.emit(mode);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  view = [600, 200]
  results = [
    {
      name: 'Sucesso',
      value: 100,
    },
    {
      name: 'ErroNegocio',
      value: 50,
    },
    {
      name: 'ErroSistema',
      value: 50,
    },
  ];
  showLegend = true;
  showLabels = true;
  colorScheme: any;
  themeSubscription: any;

  constructor() {
    
  }

  ngOnInit() {
    this.colorScheme = {
      domain: ['#5AA454', '#A10A28', '#FF8C00']
    };
  }

  OnSelect(event) {
    
  }

}

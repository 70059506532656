import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { AlertifyService } from "../services/alertify.service";
import { UserService } from "../services/user.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private alertifyService: AlertifyService,
    private router: Router,
    private userservice: UserService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status === 401) {
          
          return throwError(error.error);
        }

        if (error.status === 403) {
          // this.tokenService.removeJwtToken();
          this.userservice.logOut();

          this.router.navigateByUrl("/");

          this.alertifyService.error("Algo de errado aconteceu !");
        }

        

        if (error instanceof HttpErrorResponse) {
          const applicationError = error.headers.get("Application-Error");
          if (applicationError) {
            return throwError(applicationError);
          }

          const serverError = error.error;


          let modalStateErrors = "";

          if (serverError.errors && typeof serverError.errors === "object") {
            for (const key in serverError.errors) {
              if (serverError.errors[key]) {
                // 
                if (serverError.errors[key].message) {
                  modalStateErrors += serverError.errors[key].message + "\n";
                }

                if (!serverError.errors[key].message) {
                  modalStateErrors += serverError.errors[key] + "\n";
                }
              }
            }
          }

          if (serverError["payload"] !== undefined) {
            if (serverError["payload"]["message"]) {
              modalStateErrors = serverError["payload"]["message"];
            }
          }

          return throwError(modalStateErrors || serverError || "Server Error");
          
        }
      })
    );
  }
}

export const ErrorInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ErrorInterceptor,
  multi: true,
};

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogOrderResolveComponent } from '../dialog-order-resolve/dialog-order-resolve.component';



export interface OrderMessage {
  userEmail: string;
  message: string;
}

@Component({
  selector: 'app-dialog-order-resolve-message',
  templateUrl: './dialog-order-resolve-message.component.html',
  styleUrls: ['./dialog-order-resolve-message.component.css']
})

export class DialogOrderResolveMessageComponent{

  message: string ;
  constructor(
    public dialogRef: MatDialogRef<DialogOrderResolveMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderMessage) {}
    // @Inject(MAT_DIALOG_DATA) public message: string) {}

  onNoClick(): void {
    this.dialogRef.close();
  }


}

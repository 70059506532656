import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-concessionaria',
  templateUrl: './concessionaria.component.html',
  styleUrls: ['./concessionaria.component.css']
})
export class ConcessionariaComponent implements OnInit {

  mode = "config";

  constructor() {}

  ngOnInit() {}

  ChangeMode(mode: string) {
    this.mode = mode;
  }

}

<form [formGroup]="form">

  <section
    fxLayout="row"
    fxLayout.xs="row wrap"
    fxLayoutGap.sm="4px"
    fxLayoutGap.md="4px"
    fxLayoutGap.lg="4px"
  >
  
    <mat-form-field 
        class="mr-1"
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="100%"
        fxFlex.md="100%"
        fxFlex.lg="100%">
      <mat-label>Concessionária</mat-label>
      <mat-select (selectionChange)="OnSelectChange($event)" [(value)]="dealershipSelected">
        <mat-option disabled valid value="0">Selecione...</mat-option>
        <mat-option valid *ngFor="let item of configs" value="{{item.id}}">{{item.dealership}}</mat-option>
      </mat-select>
      <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
    </mat-form-field>
  
    <mat-form-field 
        class="mr-1"
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="100%"
        fxFlex.md="100%"
        fxFlex.lg="100%">
      <mat-label>Nome Fantásia</mat-label>
      <mat-select formControlName="fantasyName" (selectionChange)="OnSelectFantasy($event)" >
        <mat-option disabled valid value="0">Selecione...</mat-option>
        <mat-option valid *ngFor="let item of fantasies" value="{{item.id}}">{{item.registration}} - {{item.fantasyName}}</mat-option>
      </mat-select>
    </mat-form-field>
    
  </section>
</form>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <!-- Name Column -->
    <ng-container matColumnDef="invoiceDueDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Fatura Data Vencimento
      </th>
      <td mat-cell *matCellDef="let element">{{ element.invoiceDueDate }}</td>
    </ng-container>

    <ng-container matColumnDef="invoiceReferenceMonth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Fatura Data Referência
      </th>
      <td mat-cell *matCellDef="let element">{{ element.invoiceReferenceMonth }}</td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="invoiceValue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fatura Valor</th>
      <td mat-cell *matCellDef="let element">{{ element.invoiceValue | currency : 'BRL' }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="sapStatus">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fatura Status</th>
      <td mat-cell *matCellDef="let element">{{ element.sapStatus }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Fatura Data Criação Pedido
      </th>
      <td mat-cell *matCellDef="let element">{{ element.created_at | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
    </ng-container>

    <ng-container matColumnDef="acoes">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Ações
      </th>
      <td mat-cell *matCellDef="let element">
        <a target="_blank" [routerLink]="['fatura', element.id]" class="btn btn-primary buttons buttons-viseu rounded mt-2">Detalhes</a>
        <a *ngIf="element.sapStatus == 'ERRO_NEGOCIO'" class="ml-2 btn btn-warning buttons rounded mt-2" (click)="OnResolveSubmit(element.id)">Resolver</a>
      </td>

    </ng-container>
    

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</div>
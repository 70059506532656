import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CompanyGuard } from "../shared/routes-guards/company-guard";

export const routes: Routes = [

  /**
   * HAPVIDA
   */

  {
    path: ":company/concessionarias",
    loadChildren: "src/app/pages/empresas/shared/concessionaria/concessionaria.module#ConcessionariaModule",
    canActivate: [CompanyGuard]
  },

  {
    path: ":company/concessionarias/fatura/:id",
    loadChildren: "src/app/pages/empresas/hapvida/hapvida-faturas/hapvida-faturas.module#HapvidaFaturasModule",
    canActivate: [CompanyGuard]
  }

  /**
   * 
   */
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CompanyGuard]
})
export class HapvidaRoutingModule {}

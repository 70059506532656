import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  userLogo = "./assets/logo";
  userCompany = "";
  userLogged: boolean = false;

  constructor(
    private userService: UserService, 
    private router: Router
    ) { }

  ngOnInit() {
    this.userService.getUser()
      .subscribe(user => {
        if(!user) {
          this.userLogo = "./assets/logo";
          this.userLogged = false;
          this.router.navigateByUrl('login')
        };
        
        this.userLogged = this.userService.isAuthenticated();
        if(this.userLogged) {
          this.userCompany = this.userService.getUserCompany();
    
          this.userLogo = `${this.userLogo}/${this.userCompany}.png`
        };


      })
    
  }

}

<form [formGroup]="formConfig">

  <section
  fxLayout="row"
  fxLayout.xs="row wrap"
  fxFlex="100%"
  fxFlex.sm="100%"
  fxFlex.md="100%"
  fxFlex.lg="100%"
>

  <mat-form-field
      class="mr-1"
      appearance="outline"
      fxFlex="60%"
      fxFlex.sm="60%"
      fxFlex.md="60%"
      fxFlex.lg="60%">
    <mat-label>Concessionária</mat-label>
    <mat-select (selectionChange)="OnSelectDealership($event)" [(value)]="dealershipSelected">
      <input (keydown)="OnFilterConcessionaria($event)" (keyup)="OnFilterConcessionaria($event)" placeholder="Procure..." class="input-search" type="text">
      <mat-option valid value="0">Todos</mat-option>
      <mat-option valid *ngFor="let item of configsFiltered" value="{{item.id}}">{{item.dealership}}</mat-option>
    </mat-select>
    <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
  </mat-form-field>

  <div class="buttons">
    <button *ngIf="!downloading" (click)="OnDownload()" class="ml-3" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
      <mat-icon>download_all</mat-icon>
    </button>
    <div *ngIf="downloading" class="ml-3">
      <mat-progress-spinner [diameter]="50" mode="indeterminate"></mat-progress-spinner>
    </div>
  </div>

  </section>



</form>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HapvidaFaturasComponent } from './hapvida-faturas.component';
import { HapvidaFaturasRoutes } from './hapvida-faturas.routing';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    HapvidaFaturasRoutes,
    ComponentsModule,
    FormsModule,
    RouterModule
  ],
  declarations: [HapvidaFaturasComponent]
})
export class HapvidaFaturasModule { }

import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { ClaroMovelService } from "../claro-movel.service";
import { AlertifyService } from "src/app/shared/services/alertify.service";
import { ActivatedRoute } from "@angular/router";
import { ClaroMovelConfigModel } from "src/app/models/claro-movel-config";
import { ResponseModel } from "src/app/models/response";

@Component({
  selector: "app-claro-movel-config",
  templateUrl: "./claro-movel-config.component.html",
  styleUrls: ["./claro-movel-config.component.css"],
})
export class ClaroMovelConfigComponent implements OnInit {
  formConfig: FormGroup;

  private config: ClaroMovelConfigModel;

  constructor(
    private fb: FormBuilder,
    private claroMovelService: ClaroMovelService,
    private alertifyService: AlertifyService,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
  }

  private createForm() {
    this.formConfig = this.fb.group({
      username: [""],
      password: [""],
    });
  }

  ngOnInit() {
    this.config = this.activatedRoute.snapshot.data.config.data;

    if (this.config) {
      this.formConfig.get("username").setValue(this.config.username);
      this.formConfig.get("password").setValue(this.config.password);
    }
  }

  OnSubmitFormConfig() {
    this.claroMovelService
      .saveConfig(this.formConfig.value as ClaroMovelConfigModel)
      .subscribe(
        (res: ResponseModel) => {
          this.alertifyService.success(res.message);
        },
        (err: ResponseModel) => {
          this.alertifyService.error(err.error.message);
        }
      );
  }
}

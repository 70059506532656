import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "reset/token/:token",
    component: ResetPasswordComponent
  }
];

export const AuthenticationRoutes = RouterModule.forChild(routes);

import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject, Observable } from "rxjs";

/**Angular Material. */
import { MatSidenav, MatDrawer } from "@angular/material/sidenav";
import { SidenavItem } from "src/app/models/sidenav-item.model";
import { SidenavItemFactory } from "../../helpers/factory/sidenav-item.factory";
import { UserService } from "../../services/user.service";
import { UserModel } from "src/app/models/user.model";

/**Models.*/

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
})
export class MenuComponent implements OnInit, OnDestroy {
  public opened = false; //inicializar aberto
  public mode = "over"; //over para celualr
  private end: Subject<boolean> = new Subject();

  public sidenavItems: SidenavItem[] = [];

  private userCompany: string;
  
  user$: Observable<UserModel>;

  constructor(
    // private authenticationService: AuthenticationService,
    private router: Router,
    private userService: UserService,
    
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.getUser();

    this.userService.getUser()
      .subscribe(
        user => {
          if(!user)
            return
            
          this.userCompany = this.userService.getUserCompany();

          if(this.userService.isTokenExpired()) {
            this.userService.logOut();
          }

          this.sidenavItems = SidenavItemFactory.buildSidenav(this.userCompany);
        }
      )
  }

  ngOnDestroy(): void {
    this.end.next();
    this.end.complete();
  }

  public goTo(sidenavItem: SidenavItem, sidenav?: MatDrawer): void {
    this.router.navigate([sidenavItem.route]);
    //sidenav.close();
  }

  public logout(sidenav: MatDrawer): void {
    this.userService.logOut();
    sidenav.close();
  }

  OnHomeClick() {
    const company = this.userService.getUserCompany();
    this.router.navigateByUrl(`/home/${company}`)
  }
}

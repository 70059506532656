<form [formGroup]="formConfig">
  <mat-card>
    <section>
      <p class="card-title-txt">
        Favor preencher os parâmetros solicitados para configuração
      </p>

      <div class="menu">
        <div class="button-main">
          <div class="button-sub row">
            <app-button (mode)="OnModeChange($event)" [buttonName]="buttonSalvar"></app-button>
            <app-button (mode)="OnModeChange($event)" [buttonName]="buttonUpdate"></app-button>
          </div>
          <div *ngIf="formConfig.get('fantasyName').value && moduleName != 'Criar' || changeFantasyName">
            <mat-slide-toggle (change)="OnToggleFantasyName($event)" color="primary">Alterar nome fantasia?</mat-slide-toggle>
          </div>
        </div>
      </div>
    </section>

    <div >
      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
      <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%">
        <mat-label>Concessionária</mat-label>
        <mat-select (selectionChange)="OnChangeDealership($event)" [(value)]="dealershipSelected">
          <input (keydown)="OnFilterConcessionaria($event)" (keyup)="OnFilterConcessionaria($event)" placeholder="Procure..." class="input-search" type="text">
          <mat-option disabled valid value="0">Selecione...</mat-option>
          <mat-option valid *ngFor="let item of configsFiltered" value="{{item.id}}">{{item.dealership}}</mat-option>
        </mat-select>


        <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
      </mat-form-field>

      <div *ngIf="moduleName == 'Criar' || changeFantasyName; then thenFirstBlock else elseFirstBlock"></div>

      <ng-template #thenFirstBlock>
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="50%"
        >
          <mat-label>Nome Fantasia</mat-label>
          <input
            matInput
            type="text"
            placeholder="Grupo Viseu"
            formControlName="fantasyNameInput"
          />
        </mat-form-field>
      </ng-template>

       <ng-template #elseFirstBlock>
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%">
          <mat-label>Nome Fantásia</mat-label>
          <mat-select [(value)]="fantasyNameSelected" (selectionChange)="OnSelectFantasy($event)" >
            <input (keydown)="OnFilterFantasia($event)" (keyup)="OnFilterFantasia($event)" placeholder="Procure..." class="input-search" type="text">
            <mat-option disabled valid value="0">Selecione...</mat-option>
            <mat-option valid *ngFor="let item of fantasiesFiltered" [value]="item.id">{{item.fantasyName}}<span *ngIf="!item.active"> - (Desativado)</span></mat-option>

          </mat-select>
          <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
        </mat-form-field>
      </ng-template>


        <mat-form-field
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
        <mat-label>CPF/CNPJ</mat-label>
        <input
          matInput
          type="text"
          placeholder="123.456.789-99"
          formControlName="cpfCnpj"
        />
        </mat-form-field>
      </section>

      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
      <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Matrícula/Conta Contrato</mat-label>
          <input
            matInput
            type="text"
            placeholder="123456789"
            formControlName="registration"
          />
        </mat-form-field>

        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            placeholder="usuario@example.com"
            formControlName="email"
          />
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
        <mat-label>Grupo</mat-label>
        <input
          matInput
          type="text"
          placeholder="A, B"
          formControlName="group"
        />
        </mat-form-field>
      </section>

      <!-- USUARIO, SENHA E EMPRESA PAGADORA  -->
      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Usuário</mat-label>
          <input
            matInput
            type="text"
            placeholder="usuario@example.com"
            formControlName="username"
          />
        </mat-form-field>

        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
        <mat-label>Senha</mat-label>
        <input
          matInput
          type="password"
          placeholder="*****"
          formControlName="password"
          matInput [type]="hide ? 'password' : 'text'"
        />
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
        <mat-label>Empresa Pagadora</mat-label>
        <input
          matInput
          type="text"
          placeholder="1000"
          formControlName="payingCompany"
        />
        </mat-form-field>
      </section>

      <!-- CENTRO DE CUSTO E ENDEREÇO E DIAS  -->
      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Centro Custo</mat-label>
          <input
            matInput
            type="text"
            placeholder="123456"
            formControlName="costCenter"
          />
        </mat-form-field>

        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="33%"
          fxFlex.sm="33%"
          fxFlex.md="33%"
          fxFlex.lg="33%"
        >
          <mat-label>Data Leitura</mat-label>
          <input (dateChange)="OnDateChangeStart($event)" formControlName="readDate" matInput [matDatepicker]="pickerInicial">
          <mat-datepicker-toggle matSuffix [for]="pickerInicial"></mat-datepicker-toggle>
          <mat-datepicker #pickerInicial></mat-datepicker>
          <mat-error>Data inválida</mat-error>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
        <mat-label>Dias Executar (Separar por virgula)</mat-label>
        <input
          matInput
          type="text"
          placeholder="01,02,03"
          formControlName="daysRun"
        />
        </mat-form-field>


      </section>

      <!-- CENTRO FINANCEIRO, UNIDADE RESPONSAVEL E MAIL RESPONSAVEL -->
      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="33%"
          fxFlex.sm="33%"
          fxFlex.md="33%"
          fxFlex.lg="33%"
        >
          <mat-label>Centro Financeiro</mat-label>
          <input
            matInput
            type="text"
            placeholder="123456"
            formControlName="financeCenter"
          />
        </mat-form-field>

        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="33%"
          fxFlex.sm="33%"
          fxFlex.md="33%"
          fxFlex.lg="33%"
        >
          <mat-label>Unidade Responsavel</mat-label>
          <input
            matInput
            type="text"
            placeholder="ABCD"
            formControlName="responsibleUnit"
          />
        </mat-form-field>

        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="33%"
          fxFlex.sm="33%"
          fxFlex.md="33%"
          fxFlex.lg="33%"
        >
          <mat-label>Email Responsável</mat-label>
          <input
            matInput
            type="text"
            placeholder="exemplo@email.com.br"
            formControlName="responsibleEmail"
          />
          <mat-error *ngIf="formConfig.get('responsibleEmail').touched && formConfig.get('responsibleEmail').errors?.email">Email formato inválido</mat-error>
        </mat-form-field>
      </section>

      <!-- CONTATO RESPONSAVEL 1, CONTATO RESPONSAVEL 2 E DEBITO AUTOMATICO -->
      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="33%"
          fxFlex.sm="33%"
          fxFlex.md="33%"
          fxFlex.lg="33%"
        >
          <mat-label>Contato Responsável</mat-label>
          <input
            matInput
            type="text"
            placeholder="11999999999"
            formControlName="responsibleContact"
          />
        </mat-form-field>

        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="33%"
          fxFlex.sm="33%"
          fxFlex.md="33%"
          fxFlex.lg="33%"
        >
          <mat-label>Contato Responsavel 2</mat-label>
          <input
            matInput
            type="text"
            placeholder="11999999999"
            formControlName="responsibleSecondContact"
          />
        </mat-form-field>

        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%">
          <mat-label>Débito Automático</mat-label>
          <mat-select [disabled]="loading" [(value)]="debitSelected" (selectionChange)="OnChangeDebit($event)">
            <mat-option value="false">Não</mat-option>
            <mat-option value="true">Sim</mat-option>
          </mat-select>
          <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
        </mat-form-field>
      </section>

      <!-- CODIGO BARRAS -->
      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
          *ngIf="debitSelected == 'true'"
        >
          <mat-label>Codigo de Barras</mat-label>
          <input
            matInput
            type="text"
            placeholder="47 ou 48 caracteres"
            formControlName="barCode"
          />
        </mat-form-field>

      </section>

      <!-- CEP, ENDEREÇO E ESTADO -->
      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >

        <mat-form-field
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
          fxLayout="column"
        >
          <mat-label>CEP</mat-label>
          <input
            matInput
            type="text"
            placeholder="00000-000"
            formControlName="zipCode"
            maxlength="9"
            (blur)="OnCepBlur()"
          />
          <mat-error>Cep no formato inválido. Utilizar: 00000-000</mat-error>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
        <mat-label>Endereço</mat-label>
        <input
          matInput
          type="text"
          placeholder="Rua/Av ...."
          formControlName="address"
        />
        </mat-form-field>

        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%">
          <mat-label>Estado</mat-label>
          <mat-select [(value)]="stateSelected" (selectionChange)="OnChangeState()" formControlName="state">
            <mat-option disabled="true" value="">Selecione... </mat-option>
            <mat-option *ngFor="let state of states" value="{{state.sigla}}">{{state.sigla}}</mat-option>
          </mat-select>
          <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
        </mat-form-field>
      </section>

      <!-- CIDADE -->
      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >

      <mat-form-field
        class="mr-1"
        appearance="outline"
        fxFlex="33%"
        fxFlex.sm="33%"
        fxFlex.md="33%"
        fxFlex.lg="33%">
        <mat-label>Cidade</mat-label>
        <mat-select [(value)]="citySelected" formControlName="city">
          <input (keydown)="OnFilterCity($event)" (keyup)="OnFilterCity($event)" placeholder="Procure..." class="input-search" type="text">
          <mat-option disabled="true" value="">Selecione... </mat-option>
          <mat-option *ngFor="let city of citiesFiltered" value="{{city.nome}}">{{city.nome}}</mat-option>
        </mat-select>
        <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
      </mat-form-field>

      </section>

      <mat-card-content *ngIf="!loading">

        <div *ngIf="moduleName == 'Criar'; then thenBlock else elseBlock"></div>

        <ng-template #thenBlock>
          <section fxLayout="row" fxLayoutAlign="end" fxLayoutGap="4px">
            <button
              type="submit"
              mat-raised-button
              color="primary"
              [disabled]="!formConfig.valid"
              (click)="OnSubmitFormConfig()"
            >
              <mat-icon>done_all</mat-icon>
              Salvar
            </button>
          </section>
        </ng-template>

        <ng-template #elseBlock>
          <section *ngIf="formConfig.get('fantasyName').value || formConfig.get('fantasyNameInput').value"  fxLayout="row" fxLayoutAlign="end" fxLayoutGap="4px">
            <button
              type="submit"
              mat-raised-button
              color="primary"
              (click)="OnChangeFormConfigStatus()"
            >
              <mat-icon>history</mat-icon>
              <span *ngIf="formConfig.get('active').value == true">Desativar</span>
              <span *ngIf="formConfig.get('active').value == false">Ativar</span>
            </button>

            <button
              type="submit"
              mat-raised-button
              color="primary"
              [disabled]="!formConfig.valid"
              (click)="OnUpdateFormConfig()"
            >
              <mat-icon>done_all</mat-icon>
              Atualizar
            </button>

          </section>

        </ng-template>
      </mat-card-content>

      <mat-card-content *ngIf="loading">
        <mat-progress-spinner [diameter]="50" mode="indeterminate"></mat-progress-spinner>
      </mat-card-content>

    </div>

  </mat-card>
</form>

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MatSelectFilterModule } from 'mat-select-filter';

@NgModule({
  imports: [
    CommonModule,

    /**Navigation.*/
    MatSelectFilterModule
  ],
  exports: [
    MatSelectFilterModule
  ]
})
export class AngularSelectFilterModule {}

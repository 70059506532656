import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-order-resolve',
  templateUrl: './dialog-order-resolve.component.html',
  styleUrls: ['./dialog-order-resolve.component.css']
})
export class DialogOrderResolveComponent implements OnInit {

  message: string ;

  constructor(
    public dialogRef: MatDialogRef<DialogOrderResolveComponent>
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

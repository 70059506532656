import { Routes, RouterModule } from "@angular/router";
import { EnelComponent } from "./enel.component";
import { EnelConfigResolve } from "./enel-config/enel-config.resolve";
import { EnelInvoiceResolve } from "./enel-faturas/enel-faturas.resolve";

const routes: Routes = [
  {
    path: "",
    component: EnelComponent,
    resolve: {
      config: EnelConfigResolve,
      invoices: EnelInvoiceResolve,
    },
  },
];

export const EnelRoutes = RouterModule.forChild(routes);

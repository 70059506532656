import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-enel",
  templateUrl: "./enel.component.html",
  styleUrls: ["./enel.component.css"],
})
export class EnelComponent implements OnInit {
  mode = "form";

  constructor() {}

  ngOnInit() {}

  ChangeMode(mode: string) {
    this.mode = mode;
  }
}

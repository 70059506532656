import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ConcessionariaFantasyModel } from 'src/app/models/concessionaria-fantasy';
import { ConcessionariaParamModel } from 'src/app/models/concessionaria-params';
import { LogModel } from 'src/app/models/log';
import { ResponseModel } from 'src/app/models/response';
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import { ConcessionariaService } from 'src/app/shared/services/concessionaria.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { LogService } from 'src/app/shared/services/log.service';

@Component({
  selector: 'app-concessionaria-export',
  templateUrl: './concessionaria-export.component.html',
  styleUrls: ['./concessionaria-export.component.scss']
})
export class ConcessionariaExportComponent implements OnInit {

  formConfig: FormGroup
  configs: ConcessionariaParamModel[] = [];
  configsFiltered: ConcessionariaParamModel[] = [];

  configSelected: ConcessionariaParamModel;

  fantasies: ConcessionariaFantasyModel[] = []
  fantasySelected: ConcessionariaFantasyModel

  dealershipSelected: any = "0";

  logs: LogModel[] = []

  displayedColumns: string[] = [
    "logMessage",
    "logStatus",
    "created_at",
  ];
  dataSource = new MatTableDataSource<LogModel>([]);

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  downloading: boolean = false

  constructor(
    private fb: FormBuilder,
    private concessionariaService: ConcessionariaService,
    private logService: LogService,
    private activatedRoute: ActivatedRoute,
    private alertifyService: AlertifyService,
    private excelService: ExcelService

    ) {
      this.createForm();
    }

  private createForm() {
    this.formConfig = this.fb.group({
      fantasyName: [""],
    });
  }

  ngOnInit() {
    const existsConfig = this.activatedRoute.snapshot.data.config;
    if (existsConfig) {
      this.configs = existsConfig.data;
      this.configsFiltered = existsConfig.data;
    }
    this.dealershipSelected = "0"
  }

  OnSelectDealership(event) {
    this.configSelected = this.configs.filter(config => config.id == event.value)[0]
  }

  OnDownload() {
    this.downloading = true

    const dealersipId = this.configSelected ? this.configSelected.id : null

    const header = [
      [
        'MATRICULA ID','CONCESSIONARIA', 'NOME DA UNIDADE', 'CPF / CNPJ', 'MATRICULA', 'EMAIL DE ACESSO - PORTAL CONCESSIONARIA',
        'LOGIN DE  ACESSO - PORTAL CONCESSIONARIA', 'SENHA DE ACESSO - PORTAL CONCESSIONARIA',
        'GRUPO DE TENSÃO', 'COD. SAP - EMPRESA PAGADORA', 'CENTRO DE CUSTO',
        'ENDERECO DA UNIDADE', 'CEP UNIDADE', 'ESTADO UNIDADE', 'MUNICIPIO UNIDADE', 'DIAS EXECUÇÃO',
        'CENTRO FINANCEIRO', 'NOME DO RESPONSAVEL_UNID.', 'EMAIL DO RESPONSAVEL_UNID. ',
        'CONTATO RESPONSAVEL DA UNID. (1)', 'CONTATO RESPONSAVEL DA UNID. (2)', 'DEBITO AUTOMATICO', 'COD. DO DÉBITO AUTOMATICO',
        'DATA DA LEITURA', 'STATUS DA UNIDADE', 'USUARIO RESPONSAVEL POR', 'DATA ATUALIZACAO DO STATUS',
        'DATA CRIAÇÃO CONCESSIONARIA', 'DATA ATUALIZAÇÃO CONCESSIONARIA'
      ]
    ]
    this.concessionariaService.exportDealerships(dealersipId)
      .subscribe(
        (res:ResponseModel) => {
          this.excelService.exportAsExcelFile(res.data, "Concessionarias", header)
          this.downloading = false
        },
        (err: ResponseModel) => {
          //
          this.downloading = false
          this.alertifyService.error(err.message)
        }
      )
  }

  OnDateChange(event) {
    this.formConfig.get("dataInicial").setValue(event.dataInicial)
    this.formConfig.get("dataFinal").setValue(event.dataFinal)
  }

  OnFilterConcessionaria(event){
    let valor = event.target.value as String;
    if(valor == ''){
      this.configsFiltered = this.configs
      return;
    }

    this.configsFiltered = this.configs.filter(i => {
      if(i.dealership.toLowerCase().includes(valor.toLowerCase())){
        return i
      }
    })
  }

}

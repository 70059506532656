import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from "@angular/forms";
import { AlertifyService } from "src/app/shared/services/alertify.service";
import { ActivatedRoute } from "@angular/router";
import { ResponseModel } from "src/app/models/response";
import { EnelService } from "../enel.service";
import { EnelConfigModel } from "src/app/models/enel-config";
import { MatSelectChange } from "@angular/material/select";
import { ErrorStateMatcher } from "@angular/material/core";

@Component({
  selector: "app-enel-config",
  templateUrl: "./enel-config.component.html",
  styleUrls: ["./enel-config.component.css"],
})
export class EnelConfigComponent implements OnInit {
  formConfig: FormGroup;

  buttonSalvar = "Salvar";
  buttonUpdate = "Atualizar";
  moduleName = "Salvar";

  hide: boolean = true;

  configs: EnelConfigModel[] = [];

  selected: any;

  constructor(
    private fb: FormBuilder,
    private enelService: EnelService,
    private alertifyService: AlertifyService,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
  }

  private createForm() {
    this.formConfig = this.fb.group({
      username: ["", [Validators.min(1)]],
      email: [""],
      password: [""],
    });
  }

  ngOnInit() {
    const existsConfig = this.activatedRoute.snapshot.data.config;
    if (existsConfig) {
      this.configs = existsConfig.data;
    }
  }

  OnSubmitFormConfig() {
    this.enelService
      .saveConfig(this.formConfig.value as EnelConfigModel)
      .subscribe(
        (res: ResponseModel) => {
          
          if(res.data.created)
            this.configs.push(res.data);

          this.alertifyService.success(res.message);

          this.formConfig.reset();
          this.formConfig.markAsUntouched();
        },
        (err: ResponseModel) => {
          this.alertifyService.error(err.error.message);
        }
      );
  }

  OnUpdateFormConfig() {
    // 
  }

  OnDeleteFormConfig() {
    this.alertifyService.confirm(`Você deseja realmente deletar o Usuario ${this.formConfig.get('username').value}?`, () => {
    })
  }

  OnModeChange(module: string) {
    this.formConfig.reset();
    this.moduleName = module;
    if(this.moduleName == 'Atualizar') {
      this.formConfig.get('email').disable()
      this.formConfig.get('password').disable()
    }
  }

  OnSelectChange(event: MatSelectChange) {
    this.formConfig.get('username').setValue(event.value);
    this.formConfig.get('username').setValue(event.value);

    this.enelService.getConfigInfo(event.value)
      .subscribe(
        (res:ResponseModel) => {
          
          this.formConfig.get('username')
          this.formConfig.get('email').setValue(res.data.email);
          this.formConfig.get('password').setValue(res.data.password)

          this.formConfig.get('email').enable()
          this.formConfig.get('password').enable()

          
        },
        (err: ResponseModel) => {

        }
      )
    
  }
}

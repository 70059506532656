<mat-card class="container mt-5 mb-5">
  <section>
    <!-- <button [routerLink]="['/hapvida/concessionarias']" class="text-center btn btn-primary buttons rounded"> Voltar </button> -->
    <p class="card-title-txt text-center">
      Detalhes do Pedido
    </p>
  </section>

  <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
      <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Ordem ID:</mat-label>
          <input
            disabled=true
            matInput
            type="text"
            value="{{invoice.id}}"
          />
      </mat-form-field>
  
      <mat-form-field
        class="mr-1"
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="100%"
        fxFlex.md="100%"
        fxFlex.lg="100%"
      >
        <mat-label>Data de Vencimento</mat-label>
        <input
          disabled=true
          matInput
          type="text"
          value="{{invoice.invoiceDueDate}}"
        />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="100%"
        fxFlex.md="100%"
        fxFlex.lg="100%"
      >
      <mat-label>Data Referência</mat-label>
      <input
        disabled=true
        matInput
        type="text"
        value="{{invoice.invoiceReferenceMonth}}"
      />
      </mat-form-field>
  </section>

  <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
      <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Me21n Código</mat-label>
          <input
            disabled=true
            matInput
            type="text"
            value="{{invoice.sapMenCodigo || 'Não Gerado'}}"
          />
      </mat-form-field>
  
      <mat-form-field
        class="mr-1"
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="100%"
        fxFlex.md="100%"
        fxFlex.lg="100%"
      >
        <mat-label>Miro Código</mat-label>
        <input
          disabled=true
          matInput
          type="text"
          value="{{invoice.sapMiroCodigo || 'Não Gerado'}}"
        />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        fxFlex="100%"
        fxFlex.sm="100%"
        fxFlex.md="100%"
        fxFlex.lg="100%"
      >
      <mat-label>Sap Status</mat-label>
      <input
        disabled=true
        matInput
        type="text"
        value="{{invoice.sapStatus}}"
      />
      </mat-form-field>
  </section>

  <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
      <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Sap Doc Num</mat-label>
          <input
            disabled=true
            matInput
            type="text"
            value="{{invoice.sapDocNum}}"
          />
        </mat-form-field>
  
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Data Criação</mat-label>
          <input
            disabled=true
            matInput
            type="text"
            value="{{invoice.created_at | date: 'dd/MM/yyyy HH:mm:ss '}}"
          />
        </mat-form-field>
  
        <mat-form-field
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
        <mat-label>Data Atualização</mat-label>
        <input
          disabled=true
          matInput
          type="text"
          value="{{invoice.updated_at | date: 'dd/MM/yyyy HH:mm:ss '}}"
        />
        </mat-form-field>
  </section>

  <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
      <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Valor</mat-label>
          <input
            disabled=true
            matInput
            type="text"
            value="{{invoice.invoiceValue | currency : 'BRL' }}"
          />
        </mat-form-field>
  
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Status Me21n</mat-label>
          <input
            disabled=true
            matInput
            type="text"
            value="{{invoice.sapMenStatus}}"
          />
        </mat-form-field>
  
        <mat-form-field
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
        <mat-label>Status Miro</mat-label>
        <input
          disabled=true
          matInput
          type="text"
          value="{{invoice.sapMiroStatus}}"
        />
        </mat-form-field>
  </section>
  
  <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
      <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Material:</mat-label>
          <input
            disabled=true
            matInput
            type="text"
            value="{{invoice.sapMaterialText }}"
          />
        </mat-form-field>
  
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Centro Custo:</mat-label>
          <input
            disabled=true
            matInput
            type="text"
            value="{{invoice.sapCostCenter}}"
          />
        </mat-form-field>
  
        <mat-form-field
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
        <mat-label>Centro:</mat-label>
        <input
          disabled=true
          matInput
          type="text"
          value="{{invoice.sapCenter}}"
        />
        </mat-form-field>
  </section>

  <table *ngIf="invoice.sapMenStatus == 'ERRO'" class="text-center" mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <!-- Name Column -->
    <ng-container matColumnDef="Sap Me21n Erros">
      <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
        Sap Me21n Erros
      </th>
      <td mat-cell *matCellDef="let element">{{ element }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  </table>

  <table *ngIf="invoice.sapMiroStatus == 'ERRO'" mat-table [dataSource]="dataSourceMiro" matSort class="mat-elevation-z8">
    <!-- Name Column -->
    <ng-container matColumnDef="Sap Miro Erros">
      <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
        Sap Miro Erros
      </th>
      <td mat-cell *matCellDef="let element">{{ element }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsMiro"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsMiro"></tr>

  </table>

  <table *ngIf="invoice.sapStatus == 'ERRO_NEGOCIO'" mat-table [dataSource]="dataSourceErroNegocio" matSort class="mat-elevation-z8">
    <!-- Name Column -->
    <ng-container matColumnDef="Sap Erro Negocio">
      <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>
        Sap Erro Negócio
      </th>
      <td mat-cell *matCellDef="let element">{{ element }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsErroNegocio"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsErroNegocio"></tr>

  </table>

</mat-card>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IbgeLocalidadesService {

  private _baseUrl = "https://servicodados.ibge.gov.br/api/v1/localidades"
  private _stateUrl = `${this._baseUrl}/estados?orderBy=nome`;
  private _countyUrl = `${this._baseUrl}/estados/{UF}/municipios`;

  constructor(private httpClient: HttpClient,) { }

  getStates() {
    return this.httpClient.get(this._stateUrl)
  }

  getCounties(uf: string) {
    return this.httpClient.get(this._countyUrl.replace("{UF}", uf))
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ConcessionariaConfigModel } from 'src/app/models/concessionaria-config';
import { ConcessionariaFantasyModel } from 'src/app/models/concessionaria-fantasy';
import { ConcessionariaParamModel } from 'src/app/models/concessionaria-params';
import { LogModel } from 'src/app/models/log';
import { ResponseModel } from 'src/app/models/response';
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import { ConcessionariaService } from 'src/app/shared/services/concessionaria.service';
import { DownloadStaticFilesService } from 'src/app/shared/services/download-static-files.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { LogService } from 'src/app/shared/services/log.service';

@Component({
  selector: 'app-concessionaria-log',
  templateUrl: './concessionaria-log.component.html',
  styleUrls: ['./concessionaria-log.component.css']
})
export class ConcessionariaLogComponent implements OnInit {

  formConfig: FormGroup
  configs: ConcessionariaParamModel[] = [];

  configSelected: ConcessionariaParamModel;

  fantasies: ConcessionariaFantasyModel[] = []
  fantasySelected: ConcessionariaFantasyModel

  dealershipSelected: any = "0";

  logs: LogModel[] = []

  displayedColumns: string[] = [
    "logMessage",
    "logStatus",
    "created_at",
  ];
  dataSource = new MatTableDataSource<LogModel>([]);

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(
    private fb: FormBuilder,
    private concessionariaService: ConcessionariaService,
    private logService: LogService,
    private activatedRoute: ActivatedRoute,
    private alertifyService: AlertifyService,
    private excelService: ExcelService

    ) { 
      this.createForm();
    }

  private createForm() {
    this.formConfig = this.fb.group({
      fantasyName: [""],
      dataInicial: [new Date()],
      dataFinal: [new Date()]
    });
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<LogModel>(this.logs);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    const existsConfig = this.activatedRoute.snapshot.data.config;
    if (existsConfig) {
      this.configs = existsConfig.data;
    }
    this.dealershipSelected = "0"
    this.formConfig.get('fantasyName').disable()
  }

  OnSelectChange(event) {
    this.configSelected = this.configs.filter(config => config.id == event.value)[0]
    // 
    this.concessionariaService.getParamFantasy(event.value)
      .subscribe(
        (res:ResponseModel) => {

          this.fantasies = res.data
          
          this.formConfig.get('fantasyName').enable()

        },
        (err: ResponseModel) => {
          this.alertifyService.error(err.message)
        }
      )
  }

  OnSelectFantasy(event) {
    this.fantasySelected = this.fantasies.filter(fantasy => fantasy.id == event.value)[0]
    const dataInicial = this.formConfig.get("dataInicial").value;
    const dataFinal = this.formConfig.get("dataFinal").value;
    // 

    this.logService.getLogBotId(this.configSelected.bot_id, this.fantasySelected.id, dataInicial, dataFinal)
      .subscribe(
        (res:ResponseModel) => {
          // 
          this.logs = res.data
          this.dataSource = new MatTableDataSource<LogModel>(this.logs)
          this.dataSource.paginator = this.paginator;

        },
        (err: ResponseModel) => {
          // 
          this.alertifyService.error(err.message)
        }
      )
  }

  OnRefresh() {
    const dataInicial = this.formConfig.get("dataInicial").value;
    const dataFinal = this.formConfig.get("dataFinal").value;

    this.logService.getLogBotId(this.configSelected.bot_id, this.fantasySelected.id, dataInicial, dataFinal)
      .subscribe(
        (res:ResponseModel) => {
          this.logs = res.data
          this.dataSource = new MatTableDataSource<LogModel>(this.logs)
        },
        (err: ResponseModel) => {
          // 
          this.alertifyService.error(err.message)
        }
      )
  }

  OnDownload() {
    const dataInicial = this.formConfig.get("dataInicial").value;
    const dataFinal = this.formConfig.get("dataFinal").value;

    this.logService.download(this.configSelected.bot_id, this.fantasySelected.id, dataInicial, dataFinal)
      .subscribe(
        (res:ResponseModel) => {
          this.excelService.exportAsExcelFile(res.data, "Logs")
        },
        (err: ResponseModel) => {
          // 
          this.alertifyService.error(err.message)
        }
      )
  }

  OnDateChange(event) {
    this.formConfig.get("dataInicial").setValue(event.dataInicial)
    this.formConfig.get("dataFinal").setValue(event.dataFinal)
  }


}

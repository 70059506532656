import {AbstractControl, FormControl, FormGroupDirective, NgForm, ValidatorFn} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export function checkPasswords(): ValidatorFn {  
    return (control: AbstractControl): { [key: string]: any } | null =>  {
        let pass = control.get('password').value;
        let confirmPass = control.get('confirmPassword').value
        return pass === confirmPass ? null : { notSame: true }
    }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const invalidCtrl = !!(control.invalid && control.parent.dirty);
      const invalidParent = !!(control.parent.invalid && control.parent.dirty);
  
      return invalidCtrl || invalidParent;
    }
  }
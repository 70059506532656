import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ConcessionariaConfigModel } from 'src/app/models/concessionaria-config';
import { ConcessionariaInvoiceModel } from 'src/app/models/concessionaria-invoice';
import { ConcessionariaParamModel } from 'src/app/models/concessionaria-params';
import { ConcessionariaParamStateModel } from 'src/app/models/concessionaria-params-states';
import { DashboardStatus } from 'src/app/models/dashboard-status';
import { DashboardVariation } from 'src/app/models/dashboard-variation';
import { ResponseModel } from 'src/app/models/response';
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import { ConcessionariaService } from 'src/app/shared/services/concessionaria.service';
import { SapService } from 'src/app/shared/services/sap-service.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-dashboard-variacao-preco',
  templateUrl: './dashboard-variacao-preco.component.html',
  styleUrls: ['./dashboard-variacao-preco.component.css']
})
export class DashboardVariacaoPrecoComponent implements OnInit {

  view = [1000, 350]
  // results: DashboardStatus[] = [];
  dashboardVariation: DashboardVariation[] = []
  tableStatus: any[] = [] 
  
  showLegend = false;
  showLabels = true;
  colorScheme: any;
  themeSubscription: any;

  form: FormGroup;

  dealershipSelected: string = '';
  dealershipConfigSelected: string = '';
  dealershipStateSelected: string = '';

  dealership_id: string = '*';

  dealerships: ConcessionariaParamModel[];
  dealershipsStates: ConcessionariaParamStateModel[];
  dealershipsFantasies: ConcessionariaConfigModel[] = [];

  refreshTime: number = 30;
  refreshes = [
    {
      text: "30 Segundos",
      time: 30
    },
    {
      text: "60 Segundos",
      time: 60
    },
    {
      text: "120 Segundos",
      time: 120
    },
    {
      text: "180 Segundos",
      time: 180
    },
  ]
  timer: any;
  
  results = [];

  displayedColumns: string[] = [
    "monthNameReference",
    "value",
    "variation",
    "monthVariation"
  ];

  dataSource = new MatTableDataSource<DashboardVariation>([]);
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  showXAxis = true;
  showYAxis = true;
  xAxisLabel = 'Country';
  yAxisLabel = 'Population';

  constructor(
    private activatedRoute: ActivatedRoute, 
    private concessionariaService: ConcessionariaService,
    private dashboardService: DashboardService,
    private alertifyService: AlertifyService,
  ) {

    const dealerships = this.activatedRoute.snapshot.data.dealerships;
    if (dealerships) {
      this.dealerships = dealerships.data;
    }
    
    const dealershipsStates = this.activatedRoute.snapshot.data.dealershipsStates;
    if (dealershipsStates) {
      this.dealershipsStates = dealershipsStates.data;
    }

    const dealershipValuesVariations = this.activatedRoute.snapshot.data.variations;
    if (dealershipValuesVariations) {
      this.dashboardVariation = dealershipValuesVariations.data as DashboardVariation[];
    }
    this.dataSource = new MatTableDataSource<DashboardVariation>(this.dashboardVariation);

    this.colorScheme = {
      domain: [ '#003af6', '#003af6', '#0039f5', '#0039f5', '#2538f4', '#2538f4', '#a544f0', '#a544f0', '#c84ded', '#c84ded', '#e459ea', '#e459ea' ]
    };
  }

  ngOnInit() {
    this.getDashboard()
  }

  OnSelectChangeRefresh(time: number) {
    clearInterval(this.timer)
    this.timer = setInterval(() => {
      this.dashboardService.getSapMenMiroStatus(this.dealership_id)
      .subscribe(
        (res: ResponseModel) => {
          this.results = res.data
        },
        (err: ResponseModel) => {
          
          this.alertifyService.error(err.message)
        }
      )
    }, this.refreshTime * 1000)
  }

  OnSelectChangeState(event: MatSelectChange) {
    const configState = event.value;
    this.dealershipSelected = ''
    this.dealershipConfigSelected = ''

    if(configState == '') {
      this.getParams();
      this.getDashboard()
      return;
    }

    this.getParamsState(configState);

    this.getDashboard()

  }

  OnSelectChangeDealership(event: MatSelectChange) {
    const value = event.value;

    this.getDashboard()

    if(value == ''){
      this.dealershipsFantasies = []
      this.dealershipConfigSelected = ''
      return;
    }

    this.getParamsFantasy(value)
  }

  OnSelectConfig(event: MatSelectChange) {
    this.getDashboard()
  }

  OnPageChange(page) {
    
  }

  getDashboard() {
    this.dashboardService.getVariation(this.dealershipStateSelected, this.dealershipSelected, this.dealershipConfigSelected)
      .subscribe(
        (res: ResponseModel) => {
          this.dashboardVariation = res.data
          this.dataSource = new MatTableDataSource<DashboardVariation>(this.dashboardVariation);
        },
        (err: ResponseModel) => {
          this.alertifyService.error(err.message)
        }
      )
  }

  getParams() {
    this.concessionariaService.getParams(true)
      .subscribe(
        (res: ResponseModel) => {
          this.dealerships = res.data
        },
        (err: ResponseModel) => {
          
          this.alertifyService.error(err.message)
        }
      )
  }

  getParamsState(value: string) {
    this.concessionariaService.getParamsByState(value)
    .subscribe(
      (res: ResponseModel) => {
        this.dealerships = res.data
      },
      (err: ResponseModel) => {
        
        this.alertifyService.error(err.message)
      }
    )
  }

  getParamsFantasy(value: string) {
    this.concessionariaService.getParamFantasy(value)
      .subscribe(
        (res: ResponseModel) => {
          this.dealershipsFantasies = res.data
        },
        (err: ResponseModel) => {
          
          this.alertifyService.error(err.message)
        }
      )

  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConcessionariaConfigModel } from 'src/app/models/concessionaria-config';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConcessionariaService {

  private baseUrl = `${environment.apiUrl}/concessionaria`

  constructor(private httpClient: HttpClient, private userService: UserService) {}

  getParams(param_status: boolean) {
    const company_id = this.userService.getUserCompanyId()
    return this.httpClient.get(this.baseUrl + "/params/list/" + company_id, {params: {param_status: String(param_status)}});
  }

  getParamsByState(state: string) {
    return this.httpClient.get(this.baseUrl + "/params/states/list/" + state);
  }

  getParamsStates() {
    return this.httpClient.get(this.baseUrl + "/params/states/list");
  }

  getParamFantasy(param_id: string) {
    return this.httpClient.get(this.baseUrl + `/params/list/${param_id}/fantasy`)
  }

  saveConfig(config: ConcessionariaConfigModel) {

    return this.httpClient.post(
      this.baseUrl + "/config/save",
      config
    );
  }

  updateConfig(config: ConcessionariaConfigModel) {

    return this.httpClient.post(
      this.baseUrl + "/config/update",
      config
    );
  }

  deleteConfig(configId:string){
    return this.httpClient.delete(this.baseUrl + "/config/delete/" + configId)
  }

  getInvoices() {
    return this.httpClient.get(this.baseUrl + "/getInvoices");
  }

  changeStatus(configId:string, status: boolean) {
    return this.httpClient.put(this.baseUrl + "/config/update/status", {configId, status});
  }

  exportDealerships(dealershipId: string) {
    return this.httpClient.get(this.baseUrl + "/params/export/" + dealershipId)
  }

}

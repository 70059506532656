<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
  <!-- Name Column -->
  <ng-container matColumnDef="installationCode">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Código de Instalação
    </th>
    <td mat-cell *matCellDef="let element">{{ element.installationCode }}</td>
  </ng-container>

  <ng-container matColumnDef="invoiceDueDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Fatura Data Vencimento
    </th>
    <td mat-cell *matCellDef="let element">{{ element.invoiceDueDate }}</td>
  </ng-container>

  <!-- Position Column -->
  <ng-container matColumnDef="invoiceValue">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fatura Valor</th>
    <td mat-cell *matCellDef="let element">{{ element.invoiceValue }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="invoiceStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Fatura Status</th>
    <td mat-cell *matCellDef="let element">{{ element.invoiceStatus }}</td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="invoiceBarCode">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Fatura Código de barras
    </th>
    <td mat-cell *matCellDef="let element">{{ element.invoiceBarCode }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.css"],
})
export class ButtonComponent implements OnInit {
  @Output() mode = new EventEmitter();
  @Input() buttonName: string = "";
  
  constructor() {}

  ngOnInit() {}

  ChangeMode() {
    this.mode.emit(this.buttonName);
  }
}

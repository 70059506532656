import { IbgeLocalidadesService } from '../services/ibge-localidades.service';
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ConcessionariaConfigModel } from "src/app/models/concessionaria-config";

@Injectable({ providedIn: "root" })
export class IbgeConsultaEstadosResolve implements Resolve<ConcessionariaConfigModel[]> {

  constructor(private ibgeLocalidadesService: IbgeLocalidadesService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<ConcessionariaConfigModel[]>
    | Promise<ConcessionariaConfigModel[]>
    | ConcessionariaConfigModel[] {
    return this.ibgeLocalidadesService.getStates().pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }
}

import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";

const EXCEL_EXTENSION = ".xlsx";

@Injectable({
    providedIn: "root",
})
export class ExcelService {
    constructor() {}

    public exportAsExcelFile(json: any[], excelFileName: string, headers: string[][] = [[]], excelSheetName: string = "data"): void {
        const skipHeader = headers.length ? true : false;
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {skipHeader});

        XLSX.utils.sheet_add_aoa(worksheet, headers, { origin: 'A1' });

        const workbook: XLSX.WorkBook = {
          Sheets: { data: worksheet },
          SheetNames: [excelSheetName],
        };
        XLSX.writeFile(workbook, ExcelService.addExtension(excelFileName));
    }

    static addExtension(excelFileName: string): string {
        return `${excelFileName}${EXCEL_EXTENSION}`;
    }
}

import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { EnelService } from "../enel.service";
import { EnelInvoiceModel } from "src/app/models/enel-invoice";

@Injectable({ providedIn: "root" })
export class EnelInvoiceResolve implements Resolve<EnelInvoiceModel[]> {
  constructor(private enelService: EnelService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<EnelInvoiceModel[]>
    | Promise<EnelInvoiceModel[]>
    | EnelInvoiceModel[] {
    return this.enelService.getInvoices().pipe(
      catchError((error) => {
        
        return of(null);
      })
    );
  }
}

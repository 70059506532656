import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { TokenService } from "../services/token.service";
import { Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { AlertifyService } from "../services/alertify.service";

@Injectable({ providedIn: "root" })
export class HttpConfigInterceptor implements HttpInterceptor {
  private viaCepUrl = 'https://viacep.com.br'

  constructor(private tokenService: TokenService, private userService: UserService, private alertifyService: AlertifyService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let byPassUrl = false;
    if(request.url.includes(this.viaCepUrl)) {
      byPassUrl = true
    }

    const token: string = this.tokenService.getToken();

    if (token && !byPassUrl) {
      if(this.userService.isTokenExpired()) {
        this.userService.logOut();
        this.alertifyService.warning('Token expirado, faça login novamente!')
      }
      request = request.clone({
        headers: request.headers.set("X-Authorization", token),
      });
    }

    if (!request.headers.has("Content-Type")) {
      request = request.clone({
        headers: request.headers.set("Content-Type", "application/json"),
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });
    //

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event;
        }
      })
    );
  }
}

import { Routes, RouterModule } from '@angular/router';
import { HapVidaFaturaInfoResolve } from './hapvida-fatura-info.resolve';
import { HapvidaFaturasComponent } from './hapvida-faturas.component';

const routes: Routes = [
  { 
    path: "",
    component: HapvidaFaturasComponent,
    resolve: {
      invoice: HapVidaFaturaInfoResolve
    }
  },
];

export const HapvidaFaturasRoutes = RouterModule.forChild(routes);

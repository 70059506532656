<form [formGroup]="formConfig">
  <mat-card>
    <section>
      <p class="card-title-txt">
        Favor preencher os parâmetros solicitados para configuração
      </p>

      <div class="button-main">
        <div class="button-sub row">
          <app-button (mode)="OnModeChange($event)" [buttonName]="buttonSalvar"></app-button>
          <app-button (mode)="OnModeChange($event)" [buttonName]="buttonUpdate"></app-button>
        </div>
      </div>
    </section>

    <div *ngIf="moduleName == 'Salvar'">
      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >
      <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%"
        >
          <mat-label>Usuário</mat-label>
          <input
            matInput
            type="email"
            required="true"
            placeholder="Enel Sao Paulo"
            formControlName="username"
          />
        </mat-form-field>
  
        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="50%"
        >
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            minlength="3"
            maxlength="40"
            required="true"
            placeholder="usuario@example.com"
            formControlName="email"
          />
        </mat-form-field>
  
        <mat-form-field
          appearance="outline"
          fxFlex="25%"
          fxFlex.sm="25%"
          fxFlex.md="25%"
          fxFlex.lg="25%"
        >
        <mat-label>Senha</mat-label>
        <input
          matInput
          type="password"
          minlength="1"
          required="true"
          placeholder="*****"
          formControlName="password"
          matInput [type]="hide ? 'password' : 'text'"
        />
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        </mat-form-field>
      </section>
  
      <mat-card-content>
        <section fxLayout="row" fxLayoutAlign="end" fxLayoutGap="4px">
          <button
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!formConfig.valid"
            (click)="OnSubmitFormConfig()"
          >
            <mat-icon>done_all</mat-icon>
            Salvar
          </button>
        </section>
      </mat-card-content>
    </div>

    <div *ngIf="moduleName == 'Atualizar'">
      <section
        fxLayout="row"
        fxLayout.xs="row wrap"
        fxLayoutGap.sm="4px"
        fxLayoutGap.md="4px"
        fxLayoutGap.lg="4px"
      >

      <mat-form-field 
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="100%"
          fxFlex.md="100%"
          fxFlex.lg="100%">
        <mat-label>Usuário</mat-label>
        <mat-select (selectionChange)="OnSelectChange($event)" >
          <mat-option valid *ngFor="let item of configs" value="{{item.id}}">{{item.username}}</mat-option>
        </mat-select>
        <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
        <!-- <mat-error *ngIf="selected.hasError('required')">You must make a selection</mat-error> -->
      </mat-form-field>

        <mat-form-field
          class="mr-1"
          appearance="outline"
          fxFlex="100%"
          fxFlex.sm="50%"
          fxFlex.md="50%"
          fxFlex.lg="50%"
        >
          <mat-label>Email</mat-label>
          <input
            matInput
            type="email"
            minlength="3"
            maxlength="40"
            required="true"
            placeholder="usuario@example.com"
            formControlName="email"
          />
        </mat-form-field>
  
        <mat-form-field
          appearance="outline"
          fxFlex="25%"
          fxFlex.sm="25%"
          fxFlex.md="25%"
          fxFlex.lg="25%"
        >
          <mat-label>Senha</mat-label>
          <input
            matInput
            type="password"
            minlength="1"
            required="true"
            placeholder="*****"
            formControlName="password"
            matInput [type]="hide ? 'password' : 'text'"
          />
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </section>
  
      <mat-card-content>
        <section fxLayout="row" fxLayoutAlign="end" fxLayoutGap="4px">
          <button
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!formConfig.valid"
            (click)="OnUpdateFormConfig()"
          >
            <mat-icon>done_all</mat-icon>
            Atualizar
          </button>

          <button
            type="submit"
            mat-raised-button
            color="warn"
            [disabled]="!formConfig.valid"
            (click)="OnDeleteFormConfig()"
          >
            <mat-icon>delete</mat-icon>
            Deletar
          </button>
        </section>
      </mat-card-content>
    </div>

    
  </mat-card>
</form>

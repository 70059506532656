<main class="main mt-4 container" fxFlex="100%">
  <section fxLayout="column">
    <section fxFlex.xs="80%">
      
      <app-dashboard-sap-men-miro></app-dashboard-sap-men-miro>
      <br>
      <br>
      <app-dashboard-valores-barras></app-dashboard-valores-barras>
      <br>
      <br>
      <app-dashboard-variacao-preco></app-dashboard-variacao-preco>
      <br>
      <br>
  </section>
</section>
</main>

  

import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ClaroMovelConfigModel } from "src/app/models/claro-movel-config";
import { ClaroMovelService } from "../claro-movel.service";

@Injectable({ providedIn: "root" })
export class ClaroMovelConfigResolve
  implements Resolve<ClaroMovelConfigModel[]> {
  pageNumber = 1;
  pageSize = 6;

  constructor(private claroMovelService: ClaroMovelService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<ClaroMovelConfigModel[]>
    | Promise<ClaroMovelConfigModel[]>
    | ClaroMovelConfigModel[] {
    return this.claroMovelService.getConfig().pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EnelComponent } from "./enel.component";
import { ComponentsModule } from "src/app/shared/components/components.module";
import { EnelRoutes } from "./enel.routing";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { EnelCadastroComponent } from "./enel-cadastro/enel-cadastro.component";
import { EnelConfigComponent } from "./enel-config/enel-config.component";
import { EnelFaturasComponent } from "./enel-faturas/enel-faturas.component";
import { EnelService } from "./enel.service";
import { HttpConfigInterceptor } from "src/app/shared/interceptors/httpconfig.interceptor";

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    HttpClientModule,
    EnelRoutes,
  ],
  declarations: [
    EnelComponent,
    EnelCadastroComponent,
    EnelConfigComponent,
    EnelFaturasComponent,
  ],
  providers: [
    EnelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
  ],
})
export class EnelModule {}

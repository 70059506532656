import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CompanyGuard } from "../shared/routes-guards/company-guard";

export const routes: Routes = [
  {
    path: "viseu/claro-movel",
    loadChildren: "src/app/pages/empresas/template/claro-movel/claro-movel.module#ClaroMovelModule",
    canLoad: [CompanyGuard]
  },
  {
    path: "viseu/enel",
    loadChildren: "src/app/pages/empresas/template/enel/enel.module#EnelModule",
    canLoad: [CompanyGuard]

  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CompanyGuard]
})
export class TemplateRoutingModule {}

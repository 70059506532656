import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ClaroMovelConfigModel } from "src/app/models/claro-movel-config";
import { environment } from "src/environments/environment";

@Injectable()
export class ClaroMovelService {
  constructor(private httpClient: HttpClient) {}

  saveConfig(config: ClaroMovelConfigModel) {
    return this.httpClient.post(
      environment.apiUrl + "/viseu/claro-movel/saveConfig",
      config
    );
  }

  getConfig() {
    return this.httpClient.get(environment.apiUrl + "/viseu/claro-movel/getConfig");
  }

  getInvoices() {
    return this.httpClient.get(environment.apiUrl + "/viseu/claro-movel/getInvoices");
  }
}

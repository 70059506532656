import { IViaCep } from './../../../../../models/IViaCep';
import { ViacepService } from './../../../../../shared/services/viacep.service';
import { IbgeLocalidadesService } from './../../../../../shared/services/ibge-localidades.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute } from '@angular/router';
import { ConcessionariaConfigModel } from 'src/app/models/concessionaria-config';
import { ResponseModel } from 'src/app/models/response';
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import { ConcessionariaService } from 'src/app/shared/services/concessionaria.service';
import { IbgeEstado } from 'src/app/models/ibge-estado';

@Component({
  selector: 'app-concessionaria-config',
  templateUrl: './concessionaria-config.component.html',
  styleUrls: ['./concessionaria-config.component.css']
})
export class ConcessionariaConfigComponent implements OnInit {

  formConfig: FormGroup;

  buttonSalvar = "Criar";
  buttonUpdate = "Atualizar";
  moduleName = "Criar";

  hide: boolean = true;

  configs: ConcessionariaConfigModel[] = [];
  configsFiltered: ConcessionariaConfigModel[] = [];

  fantasies: ConcessionariaConfigModel[] = [];
  fantasiesFiltered: ConcessionariaConfigModel[] = [];

  configSelected: ConcessionariaConfigModel;

  dealershipSelected: any;

  changeFantasyName: boolean = false;

  fantasyNameSelected: string;

  debitSelected: string;

  loading: boolean = false;

  states: IbgeEstado[] = []

  stateSelected: string;

  cities: any[] = []
  citiesFiltered: any[] = []

  citySelected: string;

  constructor(
    private fb: FormBuilder,
    private concessionariaService: ConcessionariaService,
    private alertifyService: AlertifyService,
    private activatedRoute: ActivatedRoute,
    private ibgeLocalidadesService: IbgeLocalidadesService,
    private viacepService: ViacepService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    const existsConfig = this.activatedRoute.snapshot.data.config;
    if (existsConfig) {
      this.configs = existsConfig.data;
      this.configsFiltered = this.configs;
    }
    this.dealershipSelected = "0"
    this.debitSelected = "false"

    this.states = this.activatedRoute.snapshot.data.states;
  }

  private createForm() {
    this.formConfig = this.fb.group({
      dealership_id: ["", [Validators.min(1)]],
      fantasyNameInput: [""],
      fantasyName: [""],
      cpfCnpj: [""],
      registration: [""],
      group: [""],
      username: [""],
      email: [""],
      password: [""],
      payingCompany: [""],
      costCenter: [""],
      address: [""],
      daysRun: [""],
      financeCenter: [""],
      active: [true],
      automaticDebit: [false],
      responsibleUnit: [""],
      responsibleEmail: ["", [Validators.email]],
      responsibleContact: [""],
      responsibleSecondContact: [""],
      barCode: [""],
      readDate: [new Date()],
      zipCode: ["", [Validators.pattern("[0-9]{5}-[0-9]{3}|[0-9]{5}[0-9]{3}")]],
      city: [""],
      state: [""],
    });
  }

  OnFilterConcessionaria(event){
    let valor = event.target.value as String;
    if(valor == ''){
      this.configsFiltered = this.configs
      return;
    }


    this.configsFiltered = this.configs.filter(i => {
      if(i.dealership.toLowerCase().replace(" ", "").includes(valor.toLowerCase().replace(" ", ""))){
        return i
      }
    })
  }

  OnFilterFantasia(event){
    let valor = event.target.value as String;
    if(valor == ''){
      this.fantasiesFiltered = this.fantasies
      return;
    }

    this.fantasiesFiltered = this.fantasies.filter(i => {
      if(i.fantasyName.toLowerCase().replace(" ", "").includes(valor.toLowerCase().replace(" ", ""))){
        return i
      }
    })

  }

  OnToggleFantasyName(event: MatSlideToggleChange) {
    this.changeFantasyName = event.checked
    if(this.changeFantasyName) {
      this.formConfig.get('fantasyNameInput').enable()
      this.formConfig.get('fantasyNameInput').setValue(this.configSelected.fantasyName)
      return;
    }
    this.fantasyNameSelected = this.configSelected.id
  }

  OnChangeDebit(event: MatSelectChange) {
    this.debitSelected = event.value
    this.formConfig.get('automaticDebit').setValue(this.debitSelected == "true" ? true : false)
  }

  OnSubmitFormConfig() {
    this.formConfig.get('fantasyName').setValue(this.formConfig.get('fantasyNameInput').value)

    if(!this.formConfig.get('fantasyName').value){
      this.alertifyService.error('Informar nome fantasia!')
      return;
    }

    this.formConfig.get('city').setValue(this.citySelected)
    this.formConfig.get('state').setValue(this.stateSelected)

    const payload = this.formConfig.value

    this.loading = true;
    this.disableForm()
    this.concessionariaService
      .saveConfig(payload)
      .subscribe(
        (res: ResponseModel) => {

          this.alertifyService.success(res.message);

          this.formConfig.reset();
          this.formConfig.markAsUntouched();
          this.dealershipSelected = "0";
          this.loading = false;
        },
        (err: ResponseModel) => {
          this.enableForm()
          this.alertifyService.error(err.message);
          this.loading = false;
        },
        () =>
        {
          this.enableForm()
        }
      );
  }

  OnUpdateFormConfig() {
    this.formConfig.get('fantasyName').setValue(this.configSelected.fantasyName)
    this.changeFantasyName && this.formConfig.get('fantasyName').setValue(this.formConfig.get('fantasyNameInput').value)

    if(!this.formConfig.get('fantasyName').value){
      this.alertifyService.error('Informar nome fantasia!')
      return;
    }

    this.formConfig.get('city').setValue(this.citySelected)
    this.formConfig.get('state').setValue(this.stateSelected)

    const formToSend = {...this.formConfig.value, id: this.configSelected.id}

    this.disableForm();
    this.loading = true;

    this.concessionariaService
      .updateConfig(formToSend)
      .subscribe(
        (res: ResponseModel) => {
          const fantasyReturn = res.data as ConcessionariaConfigModel
          this.loading = false;
          this.fantasies = this.fantasies.map(fantasy => {
            if (fantasy.id == fantasyReturn.id) {
              return fantasyReturn
            }
            return fantasy
          })

          this.fantasiesFiltered = this.fantasies;

          this.configSelected = fantasyReturn;
          this.fantasyNameSelected = this.configSelected.id

          this.alertifyService.success(res.message);
          this.enableForm();
          this.loading = false;

        },
        (err: ResponseModel) => {
          console.log(this.fantasyNameSelected)
          this.alertifyService.error(err.message);
          this.loading = false;
          this.enableForm()
        }
      );
  }

  OnChangeFormConfigStatus() {
    const fantasyId: string = this.configSelected.id;
    const active: boolean = this.formConfig.get('active').value
    this.concessionariaService
      .changeStatus(fantasyId, !active)
      .subscribe(
        (res: ResponseModel) => {
          const fantasyReturn = res.data as ConcessionariaConfigModel
          this.fantasiesFiltered = this.fantasiesFiltered.map(fantasy => {
            if (fantasy.id == fantasyReturn.id) {
              return fantasyReturn
            }
            return fantasy
          })

          this.fantasyNameSelected = this.configSelected.id;

          this.formConfig.get('active').setValue(!active)
          this.alertifyService.success(res.message);
        },
        (err: ResponseModel) => {
          this.alertifyService.error(err.message);
        }
      );
  }

  OnDeleteFormConfig() {
    const id = this.configSelected.id
    this.alertifyService.confirm(`Você deseja realmente deletar o config ${this.configSelected.fantasyName}?`, () => {
      this.concessionariaService
      .deleteConfig(id)
      .subscribe(
        (res: ResponseModel) => {

          this.alertifyService.success(res.message);

          this.fantasies = this.fantasies.filter(config => {
            if(config.id != id)
              return config
          })

          this.formConfig.reset()


          this.disableForm()

        },
        (err: ResponseModel) => {
          this.alertifyService.error(err.message);
        }
      );
    })
  }

  OnModeChange(module: string) {

    if (this.moduleName == module )
      return;

    this.dealershipSelected = "0"
    this.formConfig.get('dealership_id').setValue("");

    this.formConfig.reset();

    this.formConfig.markAsUntouched();
    this.formConfig.markAsPristine();
    this.moduleName = module;

    this.fantasies = []

    this.enableForm()

    if(this.moduleName == 'Criar') {
      this.formConfig.get('fantasyName').enable()
      this.concessionariaService
      .getParams(false)
      .subscribe(
        (res: ResponseModel) => {
          this.configs = res.data;
        },
        (err: ResponseModel) => {
          this.alertifyService.error(err.error.message);
        }
      );
    }

    if(this.moduleName == 'Atualizar') {
      this.formConfig.get('fantasyName').disable()

      this.concessionariaService
      .getParams(true)
      .subscribe(
        (res: ResponseModel) => {
          this.configs = res.data;
        },
        (err: ResponseModel) => {
          this.alertifyService.error(err.error.message);
        }
      );
      this.disableForm()
    }


  }

  OnChangeDealership(event: MatSelectChange) {

    this.formConfig.get('dealership_id').value !== "" && this.cleanForm()

    this.formConfig.get('dealership_id').setValue(event.value);

    if(this.moduleName == 'Criar')
      return

    this.changeFantasyName = false;
    this.disableForm()

    if(this.moduleName == 'Atualizar') {
      this.formConfig.get('fantasyName').setValue('')
      this.formConfig.get('fantasyName').disable()
      this.concessionariaService.getParamFantasy(event.value)
      .subscribe(
        (res:ResponseModel) => {

          this.fantasies = res.data
          this.fantasiesFiltered = res.data

          this.formConfig.get('fantasyName').enable()

        },
        (err: ResponseModel) => {
          this.alertifyService.error(err.message)
        }
      )
    }
  }

  async OnSelectFantasy(event: MatSelectChange){
    this.cleanForm()
    this.configSelected = this.fantasies.filter(fantasy => fantasy.id == event.value)[0]
    Object.keys(this.formConfig.controls).forEach(field => {
      if(field in this.configSelected) {
        if(field == "automaticDebit") {
          this.debitSelected = String(this.configSelected[field])
          this.formConfig.get('automaticDebit').setValue(this.debitSelected == "true" ? true : false)
          return;
        }

        if(field == 'fantasyName') {
          return
        }
        this.formConfig.get(field).setValue(this.configSelected[field])
      }
    });

    this.stateSelected = this.configSelected.state;
    await this.OnChangeState()
    this.citySelected = this.configSelected.city;

    this.fantasyNameSelected = this.configSelected.id;
    this.formConfig.get('fantasyName').setValue(this.configSelected.fantasyName)
    this.enableForm()

  }

  disableForm(){
    Object.keys(this.formConfig.controls).forEach(field => {
      const control = this.formConfig.get(field);
      if(!'dealership_id,fantasyName'.includes(field)) {
        control.disable()
      }
    });
  }

  enableForm(){
    Object.keys(this.formConfig.controls).forEach(field => {
      const control = this.formConfig.get(field);
      if(!'dealership_id,fantasyName'.includes(field)) {
        control.enable()
      }
    });
  }

  cleanForm(){
    Object.keys(this.formConfig.controls).forEach(field => {
      const control = this.formConfig.get(field);
      if(!'dealership_id,fantasyName'.includes(field)) {
        control.setValue('')
      }
    });
  }

  OnDateChangeStart(event: any) {
    const dataLeitura = event.value

    if(!dataLeitura) {
      this.formConfig.get('readDate').setValue(new Date())
      return;
    }

  }

  async OnChangeState() {
    this.ibgeLocalidadesService.getCounties(this.stateSelected)
      .subscribe(
        ((res: any[]) => {
          this.cities = res;
          this.citiesFiltered = this.cities
        }),
        (error => {
          this.alertifyService.error('Houve um erro ao buscar os municípios. Tente novamente!')
        })
      )
  }

  OnFilterCity(event){
    let valor = event.target.value as String;
    if(valor == ''){
      this.citiesFiltered = this.cities
      return;
    }

    this.citiesFiltered = this.cities.filter(i => {
      if(i.nome.toLowerCase().replace(" ", "").includes(valor.toLowerCase().replace(" ", ""))){
        return i
      }
    })
  }

  OnCepBlur() {
    if(!this.formConfig.get('zipCode').valid || this.formConfig.get('zipCode').value.trim() == "") {
      return;
    }

    this.citySelected = ""
    this.stateSelected = ""

    const cep = this.formConfig.get('zipCode').value

    this.loading = true
    this.viacepService.getByCep(cep)
      .subscribe(
        (async (res: IViaCep) => {
          this.stateSelected = res.uf
          !this.formConfig.get('address').value && this.formConfig.get('address').setValue(res.logradouro)
          await this.OnChangeState()
          setTimeout(()=>{
            this.citySelected = res.localidade
            this.loading = false;
          }, 1000)
        }),
        (error) => {
          console.log(error)
          this.alertifyService.error('Erro ao consultar cep. Tente novamente ou preencha manual.')
          this.loading = false;
        }
      )

  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ResponseModel } from 'src/app/models/response';
import { UserModel } from 'src/app/models/user.model';
import { AlertifyService } from 'src/app/shared/services/alertify.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthenticationService } from '../authentication.service';
import { checkPasswords, MyErrorStateMatcher } from './reset-password.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  
  form: FormGroup;

  user$: Observable<any>;

  hide: boolean = true;
  hideConfirm: boolean = true;

  inputPassword: string = "password";

  matcher = new MyErrorStateMatcher()

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private alertifyService: AlertifyService,
    private router: Router,
    private userService: UserService
  ) {
    this.createForm();
  }
  
  createForm() {
    this.form = this.fb.group({
      password: ["", [Validators.required]],
      confirmPassword: ["", [Validators.required]],
    },{validators:checkPasswords()});
  }

  ngOnInit() {
    if(!this.userService.isAuthenticated()) {
      this.router.navigateByUrl('/login')
    }
  }

  submitForm() {
    const passwords = this.form.value;
    const password = this.form.get('password').value;
    const email = this.userService.getUserEmail();

    const user: any = {
      email, 
      password
    }

    this.authService.changePassword(passwords).subscribe(
      (res: ResponseModel) => {
        
        this.alertifyService.success(res.message);
        this.authService.login(user).subscribe(
          (resLogin: ResponseModel) => {
            

            this.alertifyService.success(resLogin.message);
            const userCompany = this.userService.getUserCompany();

            setTimeout(() => {
              this.router.navigateByUrl("/home/" + userCompany);
              this.userService.login(resLogin.data);
            }, 1000);
          },
          (err: ResponseModel) => {
            
            this.alertifyService.error(err.message);
          }
        );
      },
      (err: ResponseModel) => {
        
        this.alertifyService.error(err.message);
      }
    );
  }

  togglePasswordVisibility(asd) {}

  esqueciMinhaSenha() {}

}

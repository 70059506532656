import { Component, OnInit, ViewChild } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { EnelInvoiceModel } from "src/app/models/enel-invoice";

@Component({
  selector: "app-enel-faturas",
  templateUrl: "./enel-faturas.component.html",
  styleUrls: ["./enel-faturas.component.css"],
})
export class EnelFaturasComponent implements OnInit {
  private invoices: EnelInvoiceModel[] = [];
  constructor(private activatedRoute: ActivatedRoute) {
    
  }

  displayedColumns: string[] = [
    "installationCode",
    "invoiceValue",
    "invoiceDueDate",
    "invoiceStatus",
    "invoiceBarCode",
  ];
  dataSource = new MatTableDataSource<EnelInvoiceModel>([]);

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    
    this.invoices = this.activatedRoute.snapshot.data.invoices.data;
    
    this.dataSource = new MatTableDataSource<EnelInvoiceModel>(this.invoices);
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}

import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { EnelService } from "../enel.service";
import { EnelConfigModel } from "src/app/models/enel-config";

@Injectable({ providedIn: "root" })
export class EnelConfigResolve implements Resolve<EnelConfigModel[]> {
  pageNumber = 1;
  pageSize = 6;

  constructor(private enelService: EnelService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<EnelConfigModel[]>
    | Promise<EnelConfigModel[]>
    | EnelConfigModel[] {
    return this.enelService.getConfig().pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }
}

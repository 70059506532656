import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from "../authentication.service";
import { UserModel } from "src/app/models/user.model";
import { AlertifyService } from "src/app/shared/services/alertify.service";
import { ResponseModel } from "src/app/models/response";
import { Router } from "@angular/router";
import { UserService } from "src/app/shared/services/user.service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  formLogin: FormGroup;

  user$: Observable<any>;

  hide: boolean = true;

  inputPassword: string = "password";

  ambiente = environment.ambiente;

  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private alertifyService: AlertifyService,
    private router: Router,
    private userService: UserService
  ) {
    this.createForm();
  }

  createForm() {
    this.formLogin = this.fb.group({
      email: ["", [Validators.email]],
      password: "",
    });
  }

  ngOnInit() {
    this.user$ = this.userService.getUser();
  }

  submitFormLogin() {
    this.authService.login(this.formLogin.value as UserModel).subscribe(
      (res: ResponseModel) => {
        this.alertifyService.success(res.message);
        setTimeout(() => {
          this.userService.login(res.data);
          const company = this.userService.getUserCompany()
          this.router.navigateByUrl(`/home/${company}`);
        }, 1000);
      },
      (err: ResponseModel) => {
        this.alertifyService.error(err.message);
      }
    );
  }

  OnResetUser() {
    this.authService.resetUser(this.formLogin.value as UserModel).subscribe(
      (res: ResponseModel) => {
        this.alertifyService.success(res.message);
      },
      (err: ResponseModel) => {
        this.alertifyService.error(err.message);
      }
    );
  }

  togglePasswordVisibility(asd) {}

  esqueciMinhaSenha() {}
}

import { Injectable } from '@angular/core';
import * as moment from 'moment'


@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

constructor() { }

  formatar_data(data: string, formato: string = 'YYYY-MM-DD') {
    return moment(data).format(formato)
  }
}

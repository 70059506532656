import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SapMe21nMiro } from 'src/app/models/sap-me21n-miro';
import { AlertifyService } from 'src/app/shared/services/alertify.service';

@Component({
  selector: 'app-hapvida-faturas',
  templateUrl: './hapvida-faturas.component.html',
  styleUrls: ['./hapvida-faturas.component.css']
})
export class HapvidaFaturasComponent implements OnInit {

  invoice: SapMe21nMiro = null
  dataSource = new MatTableDataSource<SapMe21nMiro>([]);
  dataSourceMiro = new MatTableDataSource<SapMe21nMiro>([]);
  dataSourceErroNegocio = new MatTableDataSource<SapMe21nMiro>([]);

  @ViewChild(MatSort, { static: true }) sort: MatSort;


  displayedColumns: string[] = [
    "Sap Me21n Erros"
  ];

  displayedColumnsMiro: string[] = [
    "Sap Miro Erros"
  ];

  displayedColumnsErroNegocio: string[] = [
    "Sap Erro Negocio"
  ];
  
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private alertifyService: AlertifyService) { 
  }

  ngOnInit() {
    const invoice = this.activatedRoute.snapshot.data.invoice.data as SapMe21nMiro;
    this.invoice = {...invoice, sapCenter: invoice.sapCostCenter.substr(0,4)} 

    
    this.dataSource = new MatTableDataSource<any>(this.invoice.sapMenMensagemErro);
    this.dataSourceMiro = new MatTableDataSource<any>(this.invoice.sapMiroMensagemErro);
    this.dataSourceErroNegocio = new MatTableDataSource<any>([this.invoice.sapStatusMensagem])
  }

}

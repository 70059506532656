import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ClaroMovelService } from "../claro-movel.service";
import { AlertifyService } from "src/app/shared/services/alertify.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-claro-movel-cadastro",
  templateUrl: "./claro-movel-cadastro.component.html",
  styleUrls: ["./claro-movel-cadastro.component.css"],
})
export class ClaroMovelCadastroComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private claroMovelService: ClaroMovelService,
    private alertifyService: AlertifyService,
    private activatedRoute: ActivatedRoute
  ) {
    this.createForm();
  }

  private createForm() {
    this.form = this.fb.group({
      nome: [""],
      cpf: [""],
      numContrato: [""],
      uf: [""],
      mes: [""],
      obs1: [""],
      obs2: [""],
      ano: [""],
    });
  }

  ngOnInit() {}

  OnSubmitForm() {}
}

import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ConcessionariaConfigModel } from "src/app/models/concessionaria-config";
import { DashboardService } from "../dashboard.service";

@Injectable({ providedIn: "root" })
export class DashboardSapMenMiroResolve implements Resolve<DashboardSapMenMiroResolve[]> {

 constructor(private dashboardService: DashboardService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ):
    | Observable<DashboardSapMenMiroResolve[]>
    | Promise<DashboardSapMenMiroResolve[]>
    | DashboardSapMenMiroResolve[] {
    return this.dashboardService.getSapMenMiroStatus('*').pipe(
      catchError((error) => {
        return of(null);
      })
    );
  }
}

<form [formGroup]="form">
  <mat-form-field appearance="fill">
    <mat-label>Data Inicial</mat-label>
    <input (dateChange)="OnDateChangeStart($event)" formControlName="dataInicial" matInput [matDatepicker]="pickerInicial">
    <mat-datepicker-toggle matSuffix [for]="pickerInicial"></mat-datepicker-toggle>
    <mat-datepicker #pickerInicial></mat-datepicker>
  </mat-form-field>
  
  <mat-form-field class="ml-3" appearance="fill">
    <mat-label>Data Final</mat-label>
    <input (dateChange)="OnDateChangeFinal($event)" formControlName="dataFinal" matInput [matDatepicker]="pickerFinal">
    <mat-datepicker-toggle matSuffix [for]="pickerFinal"></mat-datepicker-toggle>
    <mat-datepicker #pickerFinal></mat-datepicker>
  </mat-form-field>
</form>
import { Routes, RouterModule } from '@angular/router';
import { ConcessionariaConfigStateResolve } from 'src/app/shared/resolves/concessionaria-config-state.resolve';
import { DashboardVariationResolve } from 'src/app/shared/resolves/concessionaria-variacao-valores';
import { ConcessionariaConfigResolve } from '../../shared/concessionaria/concessionaria-config/concessionaria-config.resolve';
import { DashboardSapMenMiroResolve } from './dashboard-sap-men-miro/dashboard-sap-men-miro-resolve';
import { DashboardComponent } from './dashboard.component';

const routes: Routes = [
  {  
    path: "",
    component: DashboardComponent,
    resolve: {
      sapMenMiroStatus: DashboardSapMenMiroResolve,
      dealerships: ConcessionariaConfigResolve,
      dealershipsStates: ConcessionariaConfigStateResolve,
      variations: DashboardVariationResolve,
    }
  },
];

export const DashboardRoutes = RouterModule.forChild(routes);

import { ConcessionariaExportComponent } from './concessionaria-export/concessionaria-export.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConcessionariaComponent } from './concessionaria.component';
import { ConcessionariaFaturasComponent } from './concessionaria-faturas/concessionaria-faturas.component';
import { ConcessionariaConfigComponent } from './concessionaria-config/concessionaria-config.component';
import { ConcessionariaLogComponent } from './concessionaria-log/concessionaria-log.component';
import { ConcessionariaRoutes } from './concessionaria.routing';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from 'src/app/shared/components/components.module';
import {MatPaginatorModule } from '@angular/material/paginator';
import {MatIconModule} from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    ConcessionariaRoutes,
    MatPaginatorModule,
    MatIconModule,
    RouterModule,
  ],
  declarations: [
    ConcessionariaComponent,
    ConcessionariaFaturasComponent,
    ConcessionariaConfigComponent,
    ConcessionariaLogComponent,
    ConcessionariaExportComponent
  ],
  providers: [
  ]
})
export class ConcessionariaModule { }

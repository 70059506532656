<section>
  <h1 mat-dialog-title>Grupo Viseu - Mensagem Ordem</h1>
  <div mat-dialog-content>
    <p>Abaixo está a descrição da resolução da ordem.</p>
    <p>Ordem Resolvida por: <strong>{{data.userEmail}}</strong></p>
    <textarea matInput
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="5"
      disabled
      [(ngModel)]="data.message"></textarea>
  </div>
  <div class="mt-3" mat-dialog-actions>
    <button class="btn-viseu" mat-button (click)="onNoClick()">Ok</button>
  </div>
</section>
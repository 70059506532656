<main class="main mt-4 container" fxFlex="100%">
  <section fxLayout="column">
    <app-button-modules (mode)="ChangeMode($event)"></app-button-modules>

    <section fxFlex.xs="80%">

      <!-- FORMULARIO CONFIGURACAO -->
      <app-concessionaria-config *ngIf="mode === 'config'"></app-concessionaria-config>

      <!-- FORMULARIO FATURAS  -->
      <app-concessionaria-faturas *ngIf="mode === 'faturas'"></app-concessionaria-faturas>

      <!-- FORMULARIO LOG  -->
      <app-concessionaria-log *ngIf="mode === 'logs'"></app-concessionaria-log>

      <!-- EXPORT CONCESSIONARIA  -->
      <app-concessionaria-export *ngIf="mode === 'export'"></app-concessionaria-export>
    </section>
  </section>
</main>

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CompanyGuard } from "../shared/routes-guards/company-guard";
import { FirstLoginGuard } from "../shared/routes-guards/first-login-guard";
import { IsLogged } from "../shared/routes-guards/is-logged";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
    // loadChildren:
    //   "src/app/pages/authentication/authentication.module#AuthenticationModule",
    // canLoad:[IsLogged]
  },
  {
    path: "login",
    loadChildren:
      "src/app/pages/authentication/authentication.module#AuthenticationModule",
    canLoad:[IsLogged]

  },
  {
    path: "home/:company",
    loadChildren: "src/app/pages/home/home.module#HomeModule",
    canActivate: [CompanyGuard, FirstLoginGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CompanyGuard]
})
export class GenericRoutingModule {}
